import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAtom } from '@reatom/npm-react';
import {
  REQUIRED,
  STRING_LENGTH,
  getValidationMessages,
} from '##/utils/validation';
import { accountIdentityAtom } from '##/atoms/accounts';

export type FormValues = {
  username: string | null;
  password: string | null;
  remember_me: boolean;
};

export const useForm = () => {
  const { t } = useTranslation();

  const [identity] = useAtom(accountIdentityAtom);

  const initialValues: FormValues = {
    username: identity ?? null,
    password: null,
    remember_me: false,
  };

  const schema = Yup.object().shape({
    username: REQUIRED(t),
    password: STRING_LENGTH(t)(4, 24).required(
      getValidationMessages(t).required,
    ),
  });

  return {
    initialValues,
    schema,
  };
};

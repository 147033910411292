import React from 'react';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Flex } from '../Layout/Flex';
import { cn } from '##/utils/bem';
import './ActionModal.css';

type Props = {
  isOpen?: boolean;
  onClose?: React.MouseEventHandler;
  title?: string;
  message?: string;
  buttonText?: string;
};

const cnActionModal = cn('ActionModal');

export const ActionModal = (props: Props) => {
  const { isOpen, onClose, title, message, buttonText } = props;

  return (
    <Modal isOpen={isOpen} style={{ zIndex: 3 }} className={cnActionModal()}>
      <Flex
        direction="column"
        align="center"
        justify="center"
        gap="var(--space-xl)"
      >
        {title && (
          <Text as="h1" align="center" weight="semibold" size="xl">
            {title}
          </Text>
        )}
        {message && (
          <Text
            as="p"
            align="center"
            size="m"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <Button onClick={onClose} label={buttonText} size="l" />
      </Flex>
    </Modal>
  );
};

import React from 'react';
import './RecoveryPassword.css';
import { Text } from '@consta/uikit/Text';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Button } from '@consta/uikit/Button';
import { cn } from '##/utils/bem';
import { cnMixAuth } from '##/mixs/MixAuth';
import { FormikInput } from '##/components/controls/formik/FormikInput';
import { useForm } from './hooks/useForm';
import { useRequest } from './hooks/useRequest';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { useAutocompleteCode } from '##/hooks/useAutocompleteCode';

const cnRecoveryPassword = cn('RecoveryPassword');

export const RecoveryPassword = () => {
  const { t } = useTranslation();

  const [navigate] = useAnchorNavigate();

  const { initialValues, schema } = useForm();

  const { isLoading, onSubmit, setCode, seconds, isBruteForceError } =
    useRequest();

  useAutocompleteCode(setCode);

  return (
    <div
      className={cnRecoveryPassword(null, [
        cnMixAuth(null, { withBottomContainer: true }),
      ])}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values) => onSubmit(values)}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
      >
        {() => (
          <Form
            className={cnRecoveryPassword('Container', [
              cnMixAuth('Container', null),
            ])}
          >
            <Text
              size="3xl"
              as="h1"
              lineHeight="xs"
              align="center"
              weight="semibold"
              className={cnRecoveryPassword('Title')}
            >
              {t('recovery_password.title')}
            </Text>
            <FormikInput
              name="password"
              type="password"
              disabled={isLoading}
              size="l"
              autoComplete="off"
              width="full"
              placeholder={t(
                'recovery_password.inputs.password.placeholder',
              )?.toString()}
            />
            <FormikInput
              name="new_password"
              type="password"
              autoComplete="off"
              disabled={isLoading}
              size="l"
              width="full"
              placeholder={t(
                'recovery_password.inputs.new_password.placeholder',
              )?.toString()}
            />
            <Button
              size="l"
              width="full"
              type="submit"
              label={t('recovery_password.button')?.toString()}
              disabled={!!seconds && isBruteForceError}
            />
          </Form>
        )}
      </Formik>
      <Text
        className={cnRecoveryPassword('Additional', [
          cnMixAuth('Additional', null),
        ])}
        size="m"
        lineHeight="s"
        as="a"
        weight="light"
        href="/login"
        onClick={navigate('/login')}
      >
        <Text weight="semibold" view="link">
          {t('recovery_password.sign_in')}
        </Text>
      </Text>
    </div>
  );
};

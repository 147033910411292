/* eslint-disable no-multi-assign */
import React from 'react';
import './MultiFactor.css';
import { useTranslation } from 'react-i18next';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import { useAtom } from '@reatom/npm-react';
import { cn } from '##/utils/bem';
import { useForm } from './hooks/useForm';
import { Flex } from '##/components/Layout/Flex';
// @ts-ignore
import Image from '##/assets/images/MultiFactor.image.svg';
import { mfaUserAuthValueAtom, mfaTypeAtom } from '##/atoms/mfa';
import { CodeInput } from '##/components/CodeInput';
import { dimensionAtom } from '##/atoms/application';
import { useAutocompleteCode } from '##/hooks/useAutocompleteCode';

const cnMultiFactor = cn('MultiFactor');

export const MultiFactor = () => {
  const { t } = useTranslation();

  const { isLoading, seconds, isBruteForceError, code, setCode, onSendCode } =
    useForm();

  const [dimension] = useAtom(dimensionAtom);

  const [mfaType] = useAtom(mfaTypeAtom);
  const [value] = useAtom(mfaUserAuthValueAtom);
  useAutocompleteCode(setCode);

  const isTimerExists = mfaType === 'email' || mfaType === 'google_totp';

  return (
    <div className={cnMultiFactor(null, [cnMixSpace({ pV: '2xl', pH: 'xl' })])}>
      {isLoading && (
        <div className={cnMultiFactor('Loader')}>
          <ProgressSpin size="2xl" />
        </div>
      )}
      <div className={cnMultiFactor('Image')}>
        <Image />
      </div>
      <div className={cnMultiFactor('Wrapper')}>
        <Text
          as="h1"
          size="3xl"
          lineHeight="xs"
          weight="semibold"
          className={cnMixSpace({ mB: 'm' })}
        >
          {t('multi_factor.title')}
        </Text>
        <Text as="p" size="m" lineHeight="m" view="secondary">
          {t(`multi_factor.data.${mfaType}.description`, { email: value })}
        </Text>
        <Flex
          direction="column"
          gap="var(--space-m)"
          align={dimension === 'mobile' ? 'center' : 'flex-start'}
          justify="flex-start"
          className={cnMixSpace({ mV: '2xl' })}
        >
          <Text as="p" size="s" lineHeight="m">
            {t(`multi_factor.data.${mfaType}.label`)}
          </Text>
          <CodeInput
            value={code}
            onChange={setCode}
            disabled={(seconds && isBruteForceError) || isLoading}
          />
        </Flex>
        {isTimerExists && (
          <>
            {seconds > 0 && (
              <Text
                as="p"
                className={cnMixSpace({ mT: 'm' })}
                size="s"
                lineHeight="m"
                view="ghost"
              >
                {t('multi_factor.timeout', { seconds })}
              </Text>
            )}
          </>
        )}
        {mfaType === 'email' && (
          <Button
            disabled={!!seconds || isLoading}
            className={cnMixSpace({ mT: 'xl' })}
            label={t(`multi_factor.button`, { seconds })?.toString()}
            onClick={onSendCode}
          />
        )}
      </div>
    </div>
  );
};

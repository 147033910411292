import { useEffect, useState } from 'react';

export const useTimer = (defaultSeconds?: number) => {
  const [seconds, setSeconds] = useState(defaultSeconds ?? 0);

  useEffect(() => {
    const timer =
      seconds > 0
        ? setInterval(() => setSeconds(seconds - 1), 1000)
        : undefined;
    return () => clearInterval(timer);
  }, [seconds]);

  return {
    seconds,
    initTimer: (seconds: number) => setSeconds(seconds),
  };
};

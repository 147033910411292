import React, { useMemo, useState } from 'react';
import { Text } from '@consta/uikit/Text';
import { TextFieldPropOnChange } from '@consta/uikit/TextField';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { AutoComplete } from '@consta/uikit/AutoCompleteCanary';
import QRCode from 'react-qr-code';
import { Button } from '@consta/uikit/Button';
import { AxiosResponse } from 'axios';
import { useFlag } from '@consta/uikit/useFlag';
import { useAction, useAtom } from '@reatom/npm-react';
import { useTranslation } from 'react-i18next';
import { Flex } from '##/components/Layout/Flex';
import { cnMfaConnectModal } from '../MfaConnectModal';
import { mfaConnect } from '##/api/actions/mfa';
import { ServerResponse } from '##/types/common';
import { mfaModeAtom, mfaTypeAtom, mfaUserAuthValueAtom } from '##/atoms/mfa';
import { inputTypeMap, validators, getAutoCompleteList } from './helper';
import { MfaType } from '##/types/mfa';
import { accountIdAtom } from '##/atoms/accounts';
import { handleError } from '##/utils/errorsConverter';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { useUuid } from '##/hooks/useUuid';

type Props = {
  onClose?: () => void;
};

export const MfaConnectModalConnection = (props: Props) => {
  const { onClose } = props;
  const [type, setType] = useAtom(mfaTypeAtom);
  const [value, setValue] = useAtom(mfaUserAuthValueAtom);
  const [isLoading, setIsLoading] = useFlag();
  const [error, setError] = useState<string | null>(null);
  const [qr, setQr] = useState<string | undefined>();
  const [submitted, setIsSubmitted] = useFlag();
  const [user_id] = useAtom(accountIdAtom);
  const [uuid] = useUuid();
  const { t } = useTranslation();

  const setMfaMode = useAction(mfaModeAtom);

  const handleChange: TextFieldPropOnChange = ({ value }) => {
    if (validators[type](value, false)) {
      setValue(value);
    }
  };

  const [navigate] = useAnchorNavigate();

  const onError = (e: AxiosResponse<ServerResponse>) => {
    const { error: message } = handleError(e, {
      defaultText:
        e.status === 401 ? t('errors.default').toString() : undefined,
    });
    if (message) {
      setError(message);
    }
  };

  const onSubmit = async (e: React.MouseEvent) => {
    try {
      if (validators[type](value, true)) {
        setIsLoading.on();
        setError(null);
        if (type === 'email' && value) {
          await mfaConnect('email', { email: value, user_id }, uuid);
          setMfaMode('inside');
          navigate('/multi_factor')(e);
        }
        if (type === 'google_totp') {
          const {
            data: { data },
          } = await mfaConnect('google_totp', { user_id }, uuid);
          setQr(data.data.qr_uri);
        }
        setIsSubmitted.on();
      } else {
        setError(t('errors.emailFormat'));
      }
    } catch (e) {
      onError(e as AxiosResponse<ServerResponse>);
    } finally {
      setIsLoading.off();
    }
  };

  const onContinue = (e: React.MouseEvent) => {
    navigate('/multi_factor')(e);
  };

  const { activeType, items } = useMemo(() => {
    const items: Array<{ type: MfaType; label: string }> = [
      {
        type: 'email',
        label: 'Email',
      },
      {
        type: 'google_totp',
        label: 'Google TOTP',
      },
    ];
    return {
      items,
      activeType: items.find((el) => el.type === type),
    };
  }, [type]);

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      gap="var(--space-xl)"
    >
      <Text as="h1" size="xl" weight="semibold">
        {t('mfa.connection.title')}
      </Text>
      <Flex
        align="flext-start"
        justify="space-between"
        wrap="wrap"
        direction="column"
        className={cnMfaConnectModal('Controls')}
        gap="var(--space-xl)"
      >
        <ChoiceGroup
          value={activeType}
          items={items}
          name="mfaType"
          className={cnMfaConnectModal('Choice')}
          onChange={({ value }) => setType(value.type)}
        />
        <Flex
          className={cnMfaConnectModal('Inputs')}
          direction="column"
          gap="var(--space-l)"
        >
          {type === 'email' && (
            <AutoComplete
              value={value}
              items={getAutoCompleteList(value)}
              getItemLabel={(item) => item}
              getItemKey={(item) => item}
              onChange={handleChange}
              disabled={isLoading}
              type={inputTypeMap[type]}
              placeholder="email@email.com"
              caption={error ?? undefined}
              status={error ? 'alert' : undefined}
              width="full"
            />
          )}
          {type === 'google_totp' && submitted && qr && (
            <Flex
              className={cnMfaConnectModal('QR')}
              align="center"
              justify="center"
            >
              <QRCode value={qr} />
            </Flex>
          )}
          <Button
            onClick={
              submitted && type === 'google_totp' ? onContinue : onSubmit
            }
            loading={isLoading}
            label={(submitted && type === 'google_totp'
              ? t('mfa.connection.continue')
              : t('mfa.connection.connect')
            )?.toString()}
            width="full"
          />
          <Button
            onClick={onClose}
            loading={isLoading}
            label={t('mfa.connection.cancel')?.toString()}
            view="secondary"
            width="full"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

import { useEffect } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { browserHistory } from '##/App';
import {
  CONFIRM,
  REGEXP_TEST,
  getValidationMessages,
} from '##/utils/validation';

export type FormValues = {
  password: string | null;
  new_password: string | null;
};

const RELOAD_KEY = 'magiclime-limid-reload';

export const useForm = () => {
  const { t } = useTranslation();
  const initialValues: FormValues = {
    password: null,
    new_password: null,
  };

  useEffect(() => {
    if (localStorage.getItem(RELOAD_KEY) === 'true') {
      localStorage.setItem(RELOAD_KEY, 'false');
      browserHistory.go(-1);
    }
    window.onbeforeunload = () => true;
    window.onunload = () => {
      localStorage.setItem(RELOAD_KEY, 'true');
    };
    return () => {
      window.onbeforeunload = null;
      window.onunload = null;
    };
  }, []);

  const schema = Yup.object().shape({
    password: REGEXP_TEST(t)(
      'password',
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
      t('errors.password.mask'),
    )
      .min(4, t('errors.password.min')?.toString())
      .max(30, t('errors.password.max')?.toString())
      .nullable()
      .required(getValidationMessages(t).required),
    new_password: CONFIRM(t)()
      .nullable()
      .required(getValidationMessages(t).required),
  });

  return {
    initialValues,
    schema,
  };
};

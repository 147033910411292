import { RouteObject } from 'react-router-dom';
import React from 'react';
import { Login } from '##/pages/Login';
import { Registration } from '##/pages/Registration';
import { Recovery } from '##/pages/Recovery';
import { PostActivation } from '##/pages/PostActivation';
import { RecoveryPassword } from '##/pages/RecoveryPassword';
import { UnverifiedEmail } from '##/pages/UnverifiedEmail';
import { MultiFactor } from '##/pages/MultiFactor';
import { MFA } from '##/pages/MFA';
import { Accounts } from '##/pages/Accounts';
import { MFASuccess } from '##/pages/MFASuccess';
import { PageWrapper } from '##/components/PageWrapper';
import { ProviderCallback } from '##/pages/ProviderCallback';
import { OauthSponsor } from '##/pages/OauthSponsor';

export const appRoutes = (): RouteObject[] => [
  {
    path: '/',
    element: (
      <PageWrapper withVideo>
        <Login />
      </PageWrapper>
    ),
  },
  {
    path: '/login',
    children: [
      {
        path: '',
        element: (
          <PageWrapper withVideo>
            <Login />
          </PageWrapper>
        ),
      },
    ],
  },
  {
    path: '/accounts',
    element: (
      <PageWrapper>
        <Accounts />
      </PageWrapper>
    ),
  },
  {
    path: '/multi_factor',
    element: (
      <PageWrapper>
        <MultiFactor />
      </PageWrapper>
    ),
  },
  {
    path: '/login/:uuid',
    element: (
      <PageWrapper withVideo>
        <Login />
      </PageWrapper>
    ),
  },
  {
    path: '/registration',
    element: (
      <PageWrapper>
        <Registration />
      </PageWrapper>
    ),
  },
  {
    path: '/recovery',
    children: [
      {
        path: 'confirm',
        element: (
          <PageWrapper>
            <RecoveryPassword />
          </PageWrapper>
        ),
      },
      {
        path: '',
        element: (
          <PageWrapper>
            <Recovery />
          </PageWrapper>
        ),
      },
    ],
  },
  {
    path: '/mfa',
    children: [
      {
        path: 'connect',
        element: (
          <PageWrapper>
            <MFA mode="connect" />
          </PageWrapper>
        ),
      },
      {
        path: 'disconnect',
        element: (
          <PageWrapper>
            <MFA mode="disconnect" />
          </PageWrapper>
        ),
      },
      {
        path: 'success',
        element: (
          <PageWrapper>
            <MFASuccess />
          </PageWrapper>
        ),
      },
    ],
  },
  {
    path: '/unverified_email',
    element: (
      <PageWrapper>
        <UnverifiedEmail />
      </PageWrapper>
    ),
  },
  {
    path: '/oauth2/:provider/callback',
    element: (
      <PageWrapper isLoading isLogicComponent>
        <ProviderCallback />
      </PageWrapper>
    ),
  },
  {
    path: '/oauth2/sponsor',
    element: (
      <PageWrapper>
        <OauthSponsor />
      </PageWrapper>
    ),
  },
];

import React from 'react';

import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { useTranslation } from 'react-i18next';
import { Flex } from '##/components/Layout/Flex';
import { cnMfaConnectModal } from '../MfaConnectModal';

type Props = {
  onClose?: () => void;
  onContinue?: () => void;
};

export const MfaConnectionModalSuggestion = (props: Props) => {
  const { onClose, onContinue } = props;

  const { t } = useTranslation();

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      gap="var(--space-xl)"
    >
      <Text as="h1" align="center" weight="semibold" size="xl">
        {t('mfa.modal.title')}
      </Text>
      <Text as="p" align="center" size="m">
        {t('mfa.modal.suggestion')}
      </Text>
      <Flex
        direction="column"
        gap="var(--space-s)"
        align="center"
        justify="center"
        className={cnMfaConnectModal('Buttons')}
      >
        <Button
          label={t('mfa.modal.cancel')?.toString()}
          view="secondary"
          width="full"
          onClick={onClose}
        />
        <Button
          label={t('mfa.modal.approve')?.toString()}
          width="full"
          onClick={onContinue}
        />
      </Flex>
    </Flex>
  );
};

import { useAction, useAtom } from '@reatom/npm-react';
import { useFlag } from '@consta/uikit/useFlag';
import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';
import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { executeIaaAction } from '##/api/actions/iaa';
import { FormValues } from './useForm';
import { isStatusSuccess } from '##/utils/requests';
import { useUuid } from '##/hooks/useUuid';
import {
  appnameAtom,
  defaultRedirectAtom,
  redirectUrlAtom,
} from '##/atoms/application';
import { ServerResponse } from '##/types/common';
import { mfaTypeAtom, mfaUserAuthValueAtom } from '##/atoms/mfa';
import { getDelayedDate } from '##/utils/date';
import { accountIdAtom } from '##/atoms/accounts';
import { handleError } from '##/utils/errorsConverter';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { useTimer } from '##/hooks/useTimer';
import { clearUuid } from '##/atoms/uuid';

const MFA_SUGGESTION_KEY = 'magiclime-limid-mfa-suggestion';

export const useRequest = () => {
  const [defaultUrl] = useAtom(defaultRedirectAtom);
  const [isLoading, setIsLoading] = useFlag();
  const [showMfa, setShowMfa] = useFlag();
  const [uuid] = useUuid();
  const { t } = useTranslation();
  const [username, setUsername] = useState<string | undefined>();

  const [redirectUrl, setUrl] = useAtom(redirectUrlAtom);
  const setAppname = useAction(appnameAtom);
  const setUserEmail = useAction(mfaUserAuthValueAtom);
  const setMfaType = useAction(mfaTypeAtom);
  const setAccountId = useAction(accountIdAtom);

  const { seconds, initTimer } = useTimer();

  const [_navigate, goToPath] = useAnchorNavigate();

  const onSubmit = async (
    values: FormValues,
    setErrors: (errors: FormikErrors<FormValues>) => void,
    defaultUuid?: string,
  ) => {
    if (uuid) {
      try {
        setIsLoading.on();
        const id = defaultUuid ?? uuid;
        const info = {
          username: values.username?.trim(),
          password: values.password?.trim(),
          remember_me: values.remember_me,
        };
        const {
          data: { data },
          status,
        } = await executeIaaAction('authentication', id, info, {
          redirect_url: defaultUrl,
        });
        if (isStatusSuccess(status)) {
          data.user_id && setAccountId(data.user_id);
          if (data.type === 'common') {
            setAppname(data.app_name);
            setUsername(info.username);
            const suggestMfa = Cookies.get(
              `${MFA_SUGGESTION_KEY}-${info.username}`,
            );
            if (data.conflicts?.includes('email_verified')) {
              goToPath('/unverified_email', false);
              return;
            }
            data.url && setUrl(data.url);
            if (
              data.conflicts?.includes('2fa_not_enabled') &&
              suggestMfa !== 'true'
            ) {
              setShowMfa.on();
            } else {
              clearUuid();
              goToPath(data.url, false, {
                target: '_self',
              });
            }
          } else if (data.type === 'mfa') {
            data.provider && setMfaType(data.provider);
            setUserEmail(data.identifier);
            goToPath('/multi_factor', false);
          } else if (data.reason === 'email_unverified') {
            setUserEmail(data.email);
            goToPath('/unverified_email', false);
          }
        }
      } catch (e) {
        const {
          data: { error },
        } = e as AxiosResponse<ServerResponse>;
        if (typeof error === 'number' && error === 2) {
          setErrors({ password: t('errors.login.unauthorized')?.toString() });
        } else {
          const { code, error, time, ...obj } = handleError(e);
          if (code === 10) {
            initTimer(Number(time));
          }
          setErrors(obj);
        }
      } finally {
        setIsLoading.off();
      }
    }
  };

  const handleCloseModal = () => {
    if (username) {
      Cookies.set(`${MFA_SUGGESTION_KEY}-${username}`, 'true', {
        expires: getDelayedDate({ days: 14 }),
      });
    }
    const url = redirectUrl ?? defaultUrl;
    if (url) {
      goToPath(url, false, { target: '_self' });
    }
  };

  return {
    isLoading,
    showMfa,
    seconds,
    onSubmit,
    closeSuggestion: handleCloseModal,
  };
};

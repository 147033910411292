import { useFlag } from '@consta/uikit/useFlag';
import { AxiosResponse } from 'axios';
import { FormikErrors } from 'formik';
import { useAction, useAtom } from '@reatom/npm-react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { isStatusSuccess } from '##/utils/requests';
import { FormValues } from './useForm';
import { recoveryPassword } from '##/api/actions/users';
import { AxiosError } from '##/types/common';
import { useUuid } from '##/hooks/useUuid';
import { userEmailAtom } from '##/atoms/application';
import { accountIdAtom } from '##/atoms/accounts';
import { handleError } from '##/utils/errorsConverter';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { useTimer } from '##/hooks/useTimer';

export const useRequest = () => {
  const [isLoading, setIsLoading] = useFlag();
  const { seconds, initTimer } = useTimer();

  const [isEmailSent, setIsEmailSent] = useState(false);

  const setUserEmail = useAction(userEmailAtom);

  const [defaultUuid] = useUuid();

  const [user_id, setUserId] = useAtom(accountIdAtom);

  const { t } = useTranslation();
  const [_navigate, goToPath] = useAnchorNavigate();

  const handleCloseEmailSentModal = () => goToPath('/', false);

  const onSubmit = async (
    values: FormValues,
    setErrors: (errors: FormikErrors<FormValues>) => void,
    uuid?: string,
  ) => {
    if (values.email) {
      try {
        setIsLoading.on();
        const {
          data: { data },
          status,
        } = await recoveryPassword(
          uuid ?? defaultUuid,
          {
            email: values.email?.trim(),
          },
          user_id,
        );
        if (isStatusSuccess(status)) {
          data.id && setUserId(data.id);
          setUserEmail(values.email ?? null);
          setIsEmailSent(true);
        }
      } catch (e) {
        const error = e as AxiosResponse<AxiosError>;
        if (error.status === 404) {
          setErrors({ email: t('recovery.errors.not_found')?.toString() });
        }
        const { code, time } = handleError(e);
        if (code === 10) {
          initTimer(Number(time));
        }
      } finally {
        setIsLoading.off();
      }
    }
  };

  return {
    isLoading,
    onSubmit,
    seconds,
    isEmailSent,
    handleCloseEmailSentModal,
  };
};

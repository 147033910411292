import { atom } from '@reatom/core';
import { onUpdate } from '@reatom/hooks';
import i18n from 'i18next';
import { setLocale } from 'yup';
// @ts-ignore
import fa from 'yup-locale-fa';
// @ts-ignore
import ru from 'yup-locale-ru';
import locale, { LocaleObject } from 'yup/lib/locale';
import { Language } from '##/types/common';

export const LANGUAGE_KEY = 'language';

const localesMap: Record<Language, LocaleObject> = {
  ru,
  fa,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  en_US: locale,
};

export const getDefaultLanguage = () => {
  const lang = localStorage.getItem(LANGUAGE_KEY) as Language;
  if (lang) return lang;
  if (!navigator?.language) return Language.EN;
  if (navigator.language.includes('ru')) return Language.RU;
  if (navigator.language.includes('fa')) return Language.FA;
  return Language.EN;
};

export const languageAtom = atom<Language>(getDefaultLanguage());

export const langaugeDirectionAtom = atom<'ltr' | 'rtl'>(
  localStorage.getItem(LANGUAGE_KEY) === Language.FA ||
    getDefaultLanguage() === Language.FA
    ? 'rtl'
    : 'ltr',
);

onUpdate(languageAtom, async (ctx, value) => {
  await i18n.changeLanguage(value);
  setLocale(localesMap[value]);
  localStorage.setItem(LANGUAGE_KEY, value);
  langaugeDirectionAtom(ctx, value === 'fa' ? 'rtl' : 'ltr');
});

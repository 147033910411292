import { useFlag } from '@consta/uikit/useFlag';
import { toast } from 'react-hot-toast';
import { useState } from 'react';
import { useAtom } from '@reatom/npm-react';
import { useTranslation } from 'react-i18next';
import { executeIaaAction } from '##/api/actions/iaa';
import { isStatusSuccess } from '##/utils/requests';
import { useUuid } from '##/hooks/useUuid';
import { FormValues } from './useForm';
import { accountIdAtom } from '##/atoms/accounts';
import { handleError } from '##/utils/errorsConverter';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { useTimer } from '##/hooks/useTimer';

export const useRequest = () => {
  const [isLoading, setIsLoading] = useFlag();
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [isBruteForceError, setIsBruteForceError] = useState<boolean>(false);

  const { seconds, initTimer } = useTimer(30);

  const { t } = useTranslation();

  const [user_id, setUserId] = useAtom(accountIdAtom);

  const [defaultUuid] = useUuid();

  const [_navigate, goToPath] = useAnchorNavigate();

  const checkCode = () => {
    const check = (val: string | undefined) => !!(val && /^\d+$/.test(val));
    let flag = true;
    code.forEach((val) => {
      if (!check(val)) {
        flag = false;
      }
    });
    if (!flag) {
      toast.error(t('recovery_password.errors.code'));
    }
    return flag;
  };

  const onSubmit = async (values: FormValues, uuid?: string) => {
    if (checkCode()) {
      try {
        setIsLoading.on();
        const {
          data: { data },
          status,
        } = await executeIaaAction(
          'emergency_change_password',
          uuid ?? defaultUuid,
          {
            new_password: values.new_password?.trim(),
            code: code.join(''),
          },
          { user_id },
        );
        if (isStatusSuccess(status)) {
          data.user_id && setUserId(data.user_id);
          toast.success(t('recovery_password.success'));
          goToPath('/login', false);
        }
      } catch (e) {
        const { code, time } = handleError(e);
        if (code === 10) {
          initTimer(Number(time));
          setIsBruteForceError(true);
        } else {
          setIsBruteForceError(false);
        }
      } finally {
        setIsLoading.off();
      }
    }
  };

  return {
    isLoading,
    onSubmit,
    seconds,
    isBruteForceError,
    setCode,
  };
};

import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { Language } from './types/common';

const getConfiguration = (): InitOptions => {
  let language = 'en_US';
  if (navigator.language) {
    if (navigator.language.includes('ru')) {
      language = Language.RU;
    } else if (navigator.language.includes('fa')) {
      language = Language.FA;
    }
  }
  return {
    compatibilityJSON: 'v3',
    fallbackLng: language,
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  };
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(getConfiguration());

export default i18n;

import { ServerResponse } from '##/types/common';
import { IaaAuthenticationResponse } from '##/types/iaa';
import { OauthIdentity, OauthProvider } from '##/types/oauth';
import { instance } from '..';
import endpoints from '../endpoints';

export const oauth2Authorize = (identity: OauthIdentity, id: string) =>
  instance.get<ServerResponse<{ url: string }>>(
    endpoints.oauth2.authorize(identity, id),
  );

export const oauth2Callback = (identity: OauthIdentity, search?: string) =>
  instance.post<ServerResponse<IaaAuthenticationResponse>>(
    endpoints.oauth2.callback(identity, search),
    {},
  );

export const getOauth2Providers = () =>
  instance.get<ServerResponse<OauthProvider[]>>(
    endpoints.oauth2.availableProviders,
  );

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAtom } from '@reatom/npm-react';
import { accountIdAtom } from '##/atoms/accounts';

export const useAutocompleteCode = (setCode: (value: string[]) => void) => {
  const [_user_id, setUserId] = useAtom(accountIdAtom);

  const [params] = useSearchParams();
  const searchCode = params.get('code');
  const userId = params.get('user_id');

  useEffect(() => {
    if (searchCode) {
      const newCode = searchCode.split('');
      setCode([...newCode]);
    }
  }, [searchCode]);

  useEffect(() => {
    if (userId) {
      setUserId(Number(userId));
    }
  }, [userId]);
};

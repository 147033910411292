import { AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import i18next from 'i18next';
import { writeConsoleMessage } from './writeConsoleMessage';
import { ServerResponse } from '##/types/common';

const keysMap: Record<number, string> = {
  10: 'time',
};

export const handleError = (
  e: any,
  params?: {
    defaultText?: string;
    withToast?: ((code: number) => boolean) | boolean;
  },
) => {
  writeConsoleMessage(e, 'critical');
  const { defaultText, withToast = true } = params ?? {};
  const {
    data: { error, message: me },
  } = e as AxiosResponse<ServerResponse>;
  if (Array.isArray(error)) {
    return Object.fromEntries(error.map(({ loc, msg }) => [loc[0], msg]));
  }
  const showToast =
    typeof withToast === 'function' ? withToast?.(error) : withToast;
  if (typeof error === 'number') {
    const key = keysMap[error];
    const message = i18next.t(
      `errors.server.code_${error}`,
      key ? { [key]: me } : {},
    );
    showToast && toast.error(message);
    return {
      error: message,
      code: error,
      time: Number(me),
    };
  }
  if (defaultText) {
    withToast && toast.error(defaultText);
    return {
      error: defaultText,
      code: error,
    };
  }

  return {};
};

import { ServerResponse } from '##/types/common';
import { IaaAuthenticationResponse } from '##/types/iaa';
import { UserAccount, UserAuthenticateData, UserUpdate } from '##/types/users';
import { instance } from '..';
import endpoints from '../endpoints';

export const authenticate = (data: UserAuthenticateData) =>
  instance.post<ServerResponse>(endpoints.users.authenticate, data);

export const getRefreshTokeb = (token: string) =>
  instance.post<ServerResponse>(endpoints.users.refresh, { token });

export const getUserInfo = () =>
  instance.get<ServerResponse>(endpoints.users.info);

export const updateUserProfile = (data: UserUpdate) =>
  instance.post<ServerResponse>(endpoints.users.profile);

export const logout = (user_id?: number) =>
  instance.post<ServerResponse>(
    endpoints.users.logout,
    {},
    {
      params: {
        user_id,
      },
    },
  );

export const forget = (user_id?: number) =>
  instance.post<ServerResponse>(
    endpoints.users.forget,
    {},
    {
      params: {
        user_id,
      },
    },
  );

export const recoveryPassword = (
  iaa: string,
  data: { email: string },
  user_id?: number,
) =>
  instance.post<ServerResponse<{ id: number }>>(
    endpoints.users.recovery(iaa),
    data,
    {
      params: {
        user_id,
      },
    },
  );

export const getSponsor = (partner_code: string) =>
  instance.get<ServerResponse<{ username: string }>>(endpoints.users.whois, {
    params: { partner_code },
  });

export const resendMail = (iaa: string, user_id?: number) =>
  instance.post<ServerResponse<{ status: boolean }>>(
    endpoints.users.resend_mail(iaa),
    {},
    {
      params: {
        user_id,
      },
    },
  );

export const getUserInSessions = () =>
  instance.get<ServerResponse<UserAccount[]>>(endpoints.users.inSesion);

export const updateSponsor = (
  iaa_id: string,
  partner_code?: string,
  user_id?: number,
) =>
  instance.put<ServerResponse<IaaAuthenticationResponse>>(
    endpoints.users.sponsor,
    {},
    { params: { partner_code, user_id, iaa_id } },
  );

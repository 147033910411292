import React, { useEffect } from 'react';
import './CodeInput.css';
import { useRefs } from '@consta/uikit/useRefs';
import { cnMixFocus } from '@consta/uikit/MixFocus';
import { cn } from '##/utils/bem';
import { useFirstRender } from '##/hooks/useFirstRender';
import { Flex } from '../Layout/Flex';

type Props = {
  onChange?: (value: string[]) => void;
  value: string[];
  className?: string;
  disabled?: boolean;
  countNumbers?: number;
};

const cnCodeInput = cn('CodeInput');

export const CodeInput = (props: Props) => {
  const { value, onChange, disabled, countNumbers = 6, className } = props;

  const isFirstRender = useFirstRender();

  const inputRefs = useRefs<HTMLInputElement>(countNumbers);

  const codeArr = [...value];

  const updateCodeArray = (
    currentIndex: number,
    newValue: string,
  ): string[] => {
    codeArr[currentIndex] = newValue;
    return codeArr;
  };

  const onInputChange =
    (currentIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const enteredValue = e.target.value.replace(/[^0-9]/g, '');

      const firstDigit = parseInt(enteredValue[0], 10);

      if (Number.isNaN(firstDigit)) return;

      if (enteredValue.length > 1) {
        const newValue =
          value[currentIndex] === enteredValue[enteredValue.length - 1]
            ? enteredValue[0]
            : enteredValue[enteredValue.length - 1];

        currentIndex !== countNumbers - 1 &&
          onChange?.(updateCodeArray(currentIndex + 1, newValue));
      } else {
        onChange?.(updateCodeArray(currentIndex, firstDigit.toString()));
      }

      if (!Number.isNaN(firstDigit)) {
        if (
          currentIndex !== countNumbers - 1 &&
          enteredValue.length !== countNumbers
        ) {
          inputRefs[currentIndex + 1].current?.focus();
        } else {
          inputRefs[countNumbers - 1].current?.blur();
        }
      }
    };

  const onInputPasedValue =
    (currentIndex: number) => (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();

      const enteredValue = e.clipboardData.getData('text');

      let iterationIndex = currentIndex;

      const countEmptyInput = countNumbers - currentIndex;

      const enteredValueArray = enteredValue
        .slice(0, countEmptyInput)
        .split('');

      enteredValueArray.forEach((number, index) => {
        if (!/^[0-9]*$/.test(number)) return;

        iterationIndex += 1;

        onChange?.(updateCodeArray(currentIndex + index, number));
      });

      inputRefs[
        iterationIndex === currentIndex ? 0 : iterationIndex
      ].current?.focus();
    };

  const onInputKeyDown =
    (currentIndex: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

      if (e.key === 'Backspace') {
        if (currentIndex !== 0 || codeArr[0]) {
          e.preventDefault();
          onChange?.(updateCodeArray(currentIndex, ''));
          inputRefs[currentIndex - 1].current?.focus();
        }
      }
    };

  useEffect(() => {
    if (isFirstRender) {
      inputRefs[0].current?.focus();
    }
  }, [value]);

  return (
    <Flex
      gap="var(--space-xs)"
      align="center"
      className={cnCodeInput(null, [className])}
      justify="flex-start"
    >
      {Array.from({ length: countNumbers }).map((_el, index) => (
        <input
          type="text"
          pattern="[0-9]*"
          inputMode="numeric"
          onChange={onInputChange(index)}
          onKeyDown={onInputKeyDown(index)}
          onPaste={onInputPasedValue(index)}
          value={value[index]}
          autoComplete="off"
          ref={inputRefs[index]}
          name={`code-${index}`}
          disabled={disabled}
          key={cnCodeInput('Input', { index })}
          className={cnCodeInput('Input', [cnMixFocus()])}
        />
      ))}
    </Flex>
  );
};

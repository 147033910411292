import { ServerResponse } from '##/types/common';
import {
  IaaAction,
  IaaDerivateResponse,
  IaaExecutedActionResponse,
} from '##/types/iaa';
import { instance } from '..';
import endpoints from '../endpoints';

export const executeIaaAction = <TYPE extends IaaAction>(
  action: TYPE,
  id: string,
  data: Record<string, unknown>,
  params?: Record<string, unknown>,
) =>
  instance.post<ServerResponse<IaaExecutedActionResponse<TYPE>>>(
    endpoints.iaa.executeAction(action, id),
    data,
    {
      params,
    },
  );

export const getIsLogged = (iaa: string | undefined, redirect_url?: string) =>
  instance.get<ServerResponse<IaaExecutedActionResponse<'authentication'>>>(
    endpoints.users.isLogged(iaa),
    {
      params: {
        redirect_url,
      },
    },
  );

export const getDefaultIaa = (action?: IaaAction) =>
  instance.get<ServerResponse<{ uuid: string }>>(endpoints.iaa.default, {
    params: {
      action: action ?? 'authentication',
    },
  });

export const getIAACompleted = (
  iaa: string,
  user_id: number | undefined,
  token?: string,
) =>
  instance.get<ServerResponse<IaaExecutedActionResponse<'authentication'>>>(
    endpoints.iaa.getIsCompleted(iaa),
    {
      params: {
        user_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

import { useAtom, useAction } from '@reatom/npm-react';
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { uuidAtom } from '##/atoms/uuid';
import { getDefaultIaa } from '##/api/actions/iaa';
import { isStatusSuccess } from '##/utils/requests';
import { defaultRedirectAtom } from '##/atoms/application';

const getUuidFromPathname = (pathname: string) => {
  if (pathname.includes('login') && !pathname.includes('activation')) {
    return pathname.split('/')[2];
  }

  return undefined;
};

export const useUuid = () => {
  const [uuid, setUuid] = useAtom(uuidAtom);

  const { pathname, search } = useLocation();

  const [searchParams] = useSearchParams();

  const iaa = searchParams.get('iaa_id');
  const redirect_url = searchParams.get('redirect_url');

  const setDefaultUrl = useAction(defaultRedirectAtom);

  const getDefaultUuid = async () => {
    const {
      data: { data },
      status,
    } = await getDefaultIaa();
    if (isStatusSuccess(status)) {
      return data.uuid;
    }
    return undefined;
  };

  const withDefaultUuid = async () => {
    const uuid = await getDefaultUuid();
    if (uuid) {
      setUuid(uuid);
    }
  };

  useEffect(() => {
    if (redirect_url) {
      setDefaultUrl(redirect_url);
    }
  }, [redirect_url]);

  useEffect(() => {
    const newUuid = iaa ?? getUuidFromPathname(pathname + search) ?? uuid;
    newUuid && setUuid(newUuid);
    if (!newUuid) {
      withDefaultUuid();
    }
  }, [iaa]);

  return [uuid];
};

import React from 'react';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { cnMixAuth } from '##/mixs/MixAuth';
import { cn } from '##/utils/bem';
import './Login.css';
import { FormikInput } from '##/components/controls/formik/FormikInput';
import { useForm } from './hooks/useForm';
import { useRequest } from './hooks/useRequest';
import { MfaConnectModal } from '##/components/MfaConnectModal';
import { FormikCheckbox } from '##/components/controls/formik/FormikCheckbox';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { Providers } from '##/components/Providers';

const cnLogin = cn('Login');

export const Login = () => {
  const { initialValues, schema } = useForm();

  const [navigate] = useAnchorNavigate();

  const { isLoading, onSubmit, showMfa, closeSuggestion, seconds } =
    useRequest();

  const { t } = useTranslation();

  return (
    <>
      <MfaConnectModal isOpen={showMfa} onClose={closeSuggestion} />
      <div
        className={cnLogin(null, [
          cnMixAuth(null, { withBottomContainer: true }),
        ])}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
        >
          {() => (
            <Form
              className={cnLogin('Container', [cnMixAuth('Container', null)])}
            >
              <Text
                as="h1"
                size="3xl"
                lineHeight="xs"
                weight="semibold"
                className={cnLogin('Title')}
              >
                {t('login.title')}
              </Text>
              <FormikInput
                name="username"
                type="text"
                size="l"
                autoComplete="username"
                disabled={isLoading}
                width="full"
                placeholder={t('login.inputs.login.placeholder')?.toString()}
              />
              <FormikInput
                type="password"
                size="l"
                name="password"
                disabled={isLoading}
                autoComplete="current-password"
                width="full"
                placeholder={t('login.inputs.password.placeholder')?.toString()}
              />
              <div className={cnLogin('Forgot')}>
                <FormikCheckbox
                  size="m"
                  name="remember_me"
                  label={t('login.save_me')?.toString()}
                  className={cnLogin('Checkbox')}
                />
                <Text
                  view="link"
                  size="s"
                  lineHeight="2xs"
                  weight="semibold"
                  as="a"
                  href="/recovery"
                  onClick={navigate('/recovery')}
                >
                  {t('login.forgot')?.toString()}
                </Text>
              </div>
              <Button
                label={
                  seconds > 0
                    ? `${t('other.resubmit', { seconds })}`
                    : t('login.button')?.toString()
                }
                size="l"
                disabled={seconds !== 0}
                loading={isLoading}
                type="submit"
                form="round"
                width="full"
              />
              <Providers label={t('login.providers')?.toString()} />
              <Text size="s" as="p">
                {t('login.law.first')}{' '}
                <Text
                  weight="semibold"
                  as="a"
                  size="s"
                  view="link"
                  href="https://docs.google.com/document/d/1IZ8tLdwLpGiJmxUdPtV66xdPEvUf8w6pLGxthINIaSQ/edit"
                >
                  {t('login.law.second')}
                </Text>
              </Text>
            </Form>
          )}
        </Formik>
        <Text
          className={cnLogin('Additional', [cnMixAuth('Additional', null)])}
          size="m"
          lineHeight="s"
          as="a"
          weight="light"
          href="/registration"
          onClick={navigate('/registration')}
        >
          <Text weight="semibold" view="link">
            {t('login.registration')}
          </Text>
        </Text>
      </div>
    </>
  );
};

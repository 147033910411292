import { atom } from '@reatom/core';
import { onUpdate } from '@reatom/hooks';
import Cookies from 'js-cookie';
import { getDelayedDate } from '##/utils/date';

const DEFAULT_REDIRECT_KEY = 'magiclime-limid-default-url';
const REDIRECT_URL_KEY = 'magiclime-limid-redirect-url';

export const isInitialLoadingAtom = atom<boolean>(false);

export const appnameAtom = atom<string | undefined>(undefined);

const redirectUrl = Cookies.get(REDIRECT_URL_KEY);

export const redirectUrlAtom = atom<string | undefined>(redirectUrl);

const defaultRedirectUrl = Cookies.get(DEFAULT_REDIRECT_KEY);

export const defaultRedirectAtom = atom<string | undefined>(defaultRedirectUrl);

export type Dimension = 'desktop' | 'tablet' | 'mobile';

export const dimensionMap: Record<Dimension, number> = {
  mobile: 0,
  tablet: 800,
  desktop: 1200,
};

export const dimensionAtom = atom<Dimension>('desktop');

onUpdate(defaultRedirectAtom, (ctx, value) => {
  if (value) {
    ctx.schedule(() =>
      Cookies.set(DEFAULT_REDIRECT_KEY, value, {
        expires: getDelayedDate({ minutes: 5 }),
      }),
    );
  }
});

onUpdate(redirectUrlAtom, (ctx, value) => {
  if (value) {
    ctx.schedule(() =>
      Cookies.set(REDIRECT_URL_KEY, value, {
        expires: getDelayedDate({ minutes: 5 }),
      }),
    );
  }
});

export const userEmailAtom = atom<string | null>(null);

import { useAction } from '@reatom/npm-react';
import { useFlag } from '@consta/uikit/useFlag';
import { useEffect, useState } from 'react';
import { FormikErrors } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormValues } from './useForm';
import { executeIaaAction } from '##/api/actions/iaa';
import { isStatusSuccess } from '##/utils/requests';
import { useUuid } from '##/hooks/useUuid';
import { getSponsor } from '##/api/actions/users';
import { redirectUrlAtom } from '##/atoms/application';
import { accountIdAtom } from '##/atoms/accounts';
import { handleError } from '##/utils/errorsConverter';
import { mfaUserAuthValueAtom } from '##/atoms/mfa';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { useTimer } from '##/hooks/useTimer';

export const useRequest = () => {
  const [isLoading, setIsLoading] = useFlag();
  const [showModal, setShowModal] = useFlag();
  const [userEmail, setUserEmail] = useState<string | undefined>();
  const [sponsorName, setSponsorName] = useState<string | undefined>();

  const setUrl = useAction(redirectUrlAtom);
  const setAccountId = useAction(accountIdAtom);
  const setEmail = useAction(mfaUserAuthValueAtom);

  const { seconds, initTimer } = useTimer();

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const partner_code = searchParams.get('partner');
  const [_navigate, goToPath] = useAnchorNavigate();

  const [defaultUuid] = useUuid();

  const onSubmit = async (
    values: FormValues,
    setErrors: (errors: FormikErrors<FormValues>) => void,
    uuid?: string,
  ) => {
    if (uuid ?? defaultUuid) {
      try {
        setIsLoading.on();
        const id = uuid ?? defaultUuid;
        const {
          status,
          data: { data },
        } = await executeIaaAction('registration', id, {
          username: values.username?.trim(),
          password: values.password?.trim(),
          email: values.email?.trim(),
          full_name: values.name?.trim(),
          partner_code: values.partner_code?.trim(),
        });
        if (isStatusSuccess(status)) {
          data.user_id && setAccountId(data.user_id);
          if (data.type === 'common' || data.type === 'mfa') {
            data.url && setUrl(data.url);
            setShowModal.on();
            setUserEmail(values.email?.trim() ?? undefined);
          } else if (data.reason === 'email_unverified') {
            setEmail(data.email);
            goToPath('/unverified_email', false);
          }
        }
      } catch (e) {
        const { code, error, time, ...obj } = handleError(e);
        setErrors(obj);
        if (code === 10) {
          initTimer(Number(time));
        }
      } finally {
        setIsLoading.off();
      }
    }
  };

  const loadSponsorName = async (code: string) => {
    try {
      const { data, status } = await getSponsor(code);
      if (isStatusSuccess(status)) {
        setSponsorName(data.data.username);
      }
    } catch {
      setSponsorName('$error_name');
    }
  };

  useEffect(() => {
    if (partner_code) {
      loadSponsorName(partner_code);
    }
  }, [partner_code]);

  return {
    isLoading,
    onSubmit,
    loadSponsorName,
    userEmail,
    isOpen: showModal,
    clearSponsor: () => setSponsorName(undefined),
    sponsorName,
    seconds,
    onClose: setShowModal.off,
  };
};

import { useAction } from '@reatom/npm-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserInSessions } from '##/api/actions/users';
import { accountsAtom } from '##/atoms/accounts';
import { isInitialLoadingAtom } from '##/atoms/application';
import { writeConsoleMessage } from '##/utils/writeConsoleMessage';
import { useAnchorNavigate } from './useAnchorNavigate';

const IGNORE_PATHS: string[] = [
  '/mfa/connect',
  '/mfa/disconnect',
  '/recovery/confirm',
];

export const useAccounts = () => {
  const setIsInitialLoading = useAction(isInitialLoadingAtom);
  const setAccounts = useAction(accountsAtom);

  const { pathname, search } = useLocation();

  const [_navigate, goToPath] = useAnchorNavigate();

  const getUserSessions = async () => {
    try {
      setIsInitialLoading(true);
      const {
        data: { data },
      } = await getUserInSessions();
      setAccounts(data);
      if (
        !IGNORE_PATHS.find((el) => pathname.includes(el)) &&
        !search.includes('code')
      ) {
        goToPath('/accounts', false);
      }
    } catch (e) {
      writeConsoleMessage(e, 'critical');
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    getUserSessions();
  }, []);
};

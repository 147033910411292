import React from 'react';
import './Accounts.css';
import { Text } from '@consta/uikit/Text';
import { useTranslation } from 'react-i18next';
import { IconAdd } from '@consta/icons/IconAdd';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Button } from '@consta/uikit/Button';
import { IconArrowLeft } from '@consta/icons/IconArrowLeft';
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import { cn } from '##/utils/bem';
import { cnMixAuth } from '##/mixs/MixAuth';
import { AccountsCard } from './AccountsCard';
import { useRequest } from './hooks/useRequests';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';

const cnAccounts = cn('Accounts');

export const Accounts = () => {
  const { t } = useTranslation();

  const [navigate] = useAnchorNavigate();

  const {
    isLoading,
    logged,
    unlogged,
    onLoggedClick,
    onUnloggedClick,
    onLogout,
  } = useRequest();

  return (
    <div
      className={cnAccounts(null, [
        cnMixAuth(null, { withBottomContainer: true }),
      ])}
    >
      {isLoading && (
        <div className={cnAccounts('Loader')}>
          <ProgressSpin size="2xl" />
        </div>
      )}
      {!isLoading && (
        <Button
          className={cnAccounts('Button')}
          form="round"
          view="ghost"
          as="a"
          onClick={navigate('/')}
          href="/"
          iconLeft={IconArrowLeft}
        />
      )}
      <div className={cnAccounts('Container', [cnMixAuth('Container', null)])}>
        <Text
          as="h1"
          align="center"
          size="3xl"
          lineHeight="xs"
          weight="semibold"
        >
          {t('accounts.title')}
        </Text>
        {logged.length > 0 && (
          <div
            className={cnAccounts('Block', [
              cnMixSpace({ mT: 'xl', mB: 'xs' }),
            ])}
          >
            <Text as="p" size="m" weight="medium">
              {t('accounts.active_accounts')}
            </Text>
            <div className={cnAccounts('Divider')} />
            {logged.map((item) => (
              <AccountsCard
                account={item}
                onClick={onLoggedClick(item)}
                onLogout={onLogout(item)}
                key={cnAccounts('Card', { id: item.id })}
              />
            ))}
          </div>
        )}
        {unlogged.length > 0 && (
          <div className={cnAccounts('Block', [cnMixSpace({ mT: 'xl' })])}>
            <Text as="p" size="m" weight="medium">
              {t('accounts.logout_accounts')}
            </Text>
            <div className={cnAccounts('Divider')} />
            {unlogged.map((item) => (
              <AccountsCard
                onClick={onUnloggedClick(item)}
                account={item}
                onLogout={onLogout(item)}
                key={cnAccounts('Card', { id: item.id })}
              />
            ))}
          </div>
        )}
      </div>
      <a
        href="/"
        onClick={navigate('/')}
        className={cnAccounts('Additional', [cnMixAuth('Additional', null)])}
      >
        <div className={cnAccounts('Icon')}>
          <IconAdd />
        </div>
        <Text as="p" align="left" size="m" lineHeight="xs">
          {t('accounts.other_account')}
        </Text>
      </a>
    </div>
  );
};

import { useEffect } from 'react';

type UseIntervalCallbackType = {
  interval?: number;
  callback: () => void;
};

export const useIntervalCallback = ({
  callback,
  interval = 5000,
}: UseIntervalCallbackType): void => {
  useEffect((): (() => void) => {
    let timerId: NodeJS.Timeout;

    timerId = setTimeout(function executeCallback() {
      callback();
      timerId = setTimeout(executeCallback, interval);
    }, interval);

    return (): void => clearTimeout(timerId);
  }, []);
};

import React, { useEffect, useState } from 'react';
import { Text } from '@consta/uikit/Text';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useAtom } from '@reatom/npm-react';
import { Button } from '@consta/uikit/Button';
import { useFlag } from '@consta/uikit/useFlag';
import { cn } from '##/utils/bem';
import './OauthSponsor.css';
import { cnMixAuth } from '##/mixs/MixAuth';
import { useForm } from './hooks/useForm';
import { FormikInput } from '##/components/controls/formik/FormikInput';
import { useDebounce } from '##/hooks/useDebounce';
import { redirectUrlAtom } from '##/atoms/application';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { WarningModal } from '##/pages/OauthSponsor/WarningModal';

const cnOauthSponsor = cn('OauthSponsor');

export const OauthSponsor = () => {
  const { t } = useTranslation();

  const {
    initialValues,
    schema,
    onSubmit,
    sponsorName,
    loadSponsorName,
    clearSponsor,
    isLoading,
    seconds,
  } = useForm();

  const [openModal, setOpenModal] = useFlag(false);

  const [navigate] = useAnchorNavigate();

  const [redurectUrl] = useAtom(redirectUrlAtom);

  const [code, setCode] = useState<string | null>(null);

  const debouncedCode = useDebounce(code, 300);

  const handleClick = () => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenModal.on();
  };

  useEffect(() => {
    if (debouncedCode) {
      loadSponsorName(debouncedCode);
    } else {
      clearSponsor();
    }
  }, [debouncedCode]);

  return (
    <div
      className={cnOauthSponsor(null, [
        cnMixAuth(null, { withBottomContainer: true }),
      ])}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={(values, helpers) => onSubmit(values, false, helpers)}
      >
        {({ setFieldValue }) => (
          <Form
            className={cnOauthSponsor('Container', [
              cnMixAuth('Container', null),
            ])}
          >
            <Text
              as="h1"
              size="3xl"
              lineHeight="xs"
              weight="semibold"
              className={cnOauthSponsor('Title')}
            >
              {t('oauth_sponsor.title')}
            </Text>

            <FormikInput
              name="partner_code"
              type="text"
              status={sponsorName === '$error_name' ? 'alert' : undefined}
              caption={
                sponsorName
                  ? sponsorName === '$error_name'
                    ? t('registration.sponsor.not_found')?.toString()
                    : t('registration.sponsor.name', {
                        sponsorName,
                      })?.toString()
                  : undefined
              }
              onChange={({ value }) => {
                setCode(value);
                setFieldValue('partner_code', value);
              }}
              placeholder={t(
                'registration.inputs.partner_code.placeholder',
              )?.toString()}
              label={t('registration.inputs.partner_code.label')?.toString()}
              size="l"
              disabled={isLoading}
              width="full"
            />
            <Button
              size="l"
              type="submit"
              width="full"
              onClick={setOpenModal.off}
              label={t('oauth_sponsor.modal.change_sponsor')?.toString()}
            />
          </Form>
        )}
      </Formik>
      <Text
        className={cnOauthSponsor('Additional', [
          cnMixAuth('Additional', null),
        ])}
        size="m"
        lineHeight="s"
        weight="light"
        href={redurectUrl}
        target="_self"
        as="a"
        onClick={handleClick()}
      >
        {t('oauth_sponsor.skip')}
      </Text>
      <WarningModal
        onClick={() => onSubmit({ partner_code: null })}
        isOpen={openModal}
        onClose={setOpenModal.off}
      />
    </div>
  );
};

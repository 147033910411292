import React, { useEffect, useState } from 'react';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { useTranslation } from 'react-i18next';
import { useAtom } from '@reatom/npm-react';
import { useFlag } from '@consta/uikit/useFlag';
import { AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { defaultRedirectAtom, redirectUrlAtom } from '##/atoms/application';
import { logout, resendMail } from '##/api/actions/users';
import { cn } from '##/utils/bem';
import { Flex } from '##/components/Layout/Flex';
import './UnverifiedEmail.css';
import { cnMixAuth } from '##/mixs/MixAuth';
import { isStatusSuccess } from '##/utils/requests';
import { CodeInput } from '##/components/CodeInput';
import { useUuid } from '##/hooks/useUuid';
import { executeIaaAction } from '##/api/actions/iaa';
import { accountIdAtom } from '##/atoms/accounts';
import { handleError } from '##/utils/errorsConverter';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { MfaConnectModal } from '##/components/MfaConnectModal';
import { useTimer } from '##/hooks/useTimer';
import { useAutocompleteCode } from '##/hooks/useAutocompleteCode';
import { checkIaaIsCompleted } from '##/utils/iaa_completed';
import { useIntervalCallback } from '##/hooks/useIntervalCallback';
import { browserHistory } from '##/App';

const RELOAD_KEY = 'magiclime-limid-reload';

const cnUnverifiedEmail = cn('UnverifiedEmail');

export const UnverifiedEmail = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useFlag();
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [isBruteForceError, setIsBruteForceError] = useState<boolean>(false);

  const { seconds, initTimer } = useTimer(30);

  const [showMfa, setShowMfa] = useFlag();

  const [navigate, goToPath] = useAnchorNavigate();

  const [redirectUrl] = useAtom(redirectUrlAtom);
  const [defaultUrl] = useAtom(defaultRedirectAtom);
  const [params] = useSearchParams();
  const suggest_mfa = params.get('suggest_mfa');

  const [user_id] = useAtom(accountIdAtom);
  const [uuid] = useUuid();

  useAutocompleteCode(setCode);

  const resubmit = async () => {
    if (isBruteForceError) setIsBruteForceError(false);

    try {
      setLoading.on();
      const { status } = await resendMail(uuid, user_id);
      if (isStatusSuccess(status)) {
        toast.success(t('unverified_email.success'));
      }
      initTimer(60);
    } catch (e) {
      const error = e as AxiosResponse;
      const defaultText =
        Math.floor(error.status / 100) !== 5
          ? t('unverified_email.errors.email')
          : t('unverified_email.errors.server');
      const { time, code } = handleError(e, {
        defaultText,
      });

      if (error.status === 429) {
        console.log('err', error);
      }

      if (code === 10) {
        initTimer(Number(time));
      } else {
        initTimer(60);
      }
    } finally {
      setLoading.off();
    }
  };

  const logoutAction = () => {
    if (uuid) {
      logout(user_id);
    }
  };

  const onCancel = (e: React.MouseEvent) => {
    logoutAction();
    navigate('/login')(e);
  };

  const onComplete = async (uuid: string) => {
    try {
      setLoading.on();
      const {
        status,
        data: { data },
      } = await executeIaaAction(
        'post_registration_activation',
        uuid,
        {
          code: code.join(''),
        },
        {
          user_id,
        },
      );
      if (isStatusSuccess(status)) {
        if (suggest_mfa !== 'true') {
          const url = data.url ?? redirectUrl ?? defaultUrl;
          if (url) {
            window.onbeforeunload = null;
            window.onunload = null;

            window.location.replace(url);
          }
        } else {
          setShowMfa.on();
        }
      } else {
        setCode(['', '', '', '', '', '']);
      }
    } catch (e) {
      const { time, code } = handleError(e, {
        defaultText: t('unverified_email.errors.code').toString(),
        withToast: (code) => code === 10 || code === 301,
      });
      if (code === 10) {
        initTimer(Number(time));
        setIsBruteForceError(true);
      }
      setCode(['', '', '', '', '', '']);
    } finally {
      setLoading.off();
    }
  };

  const handleCloseModal = () => {
    const url = redirectUrl ?? defaultUrl;
    setShowMfa.off();
    if (url) {
      goToPath(url, false, { target: '_self' });
    }
  };

  useIntervalCallback({
    callback: () => checkIaaIsCompleted(uuid, user_id),
  });

  useEffect(() => {
    if (localStorage.getItem(RELOAD_KEY) === 'true') {
      localStorage.setItem(RELOAD_KEY, 'false');
      browserHistory.go(-1);
    }
    window.onbeforeunload = () => true;
    window.onunload = () => {
      localStorage.setItem(RELOAD_KEY, 'true');
    };
    return () => {
      window.onbeforeunload = null;
      window.onunload = null;
    };
  }, []);

  useEffect(() => {
    const check = (val: string | undefined) => !!(val && /^\d+$/.test(val));
    let flag = true;
    code.forEach((val) => {
      if (!check(val)) {
        flag = false;
      }
    });
    if (flag) {
      onComplete(uuid);
    }
  }, [code]);

  return (
    <Flex
      direction="column"
      gap="var(--space-xl)"
      align="center"
      className={cnUnverifiedEmail(null, [
        cnMixAuth(null, { withBottomContainer: false }),
      ])}
    >
      <MfaConnectModal isOpen={showMfa} onClose={handleCloseModal} />
      <Text
        as="h1"
        size="3xl"
        lineHeight="xs"
        weight="semibold"
        align="center"
        className={cnUnverifiedEmail('Title')}
      >
        {t('unverified_email.title')}
      </Text>
      <Text as="p" align="center" size="m">
        {t('unverified_email.message')}
      </Text>
      <CodeInput
        value={code}
        onChange={setCode}
        disabled={(seconds && isBruteForceError) || loading}
      />
      {seconds > 0 && (
        <Text
          as="p"
          className={cnMixSpace({ mT: 'm' })}
          size="s"
          lineHeight="m"
          view="ghost"
        >
          {t('multi_factor.timeout', { seconds })}
        </Text>
      )}
      <Button
        label={t('unverified_email.button')?.toString()}
        size="l"
        width="full"
        loading={loading}
        disabled={seconds > 0}
        onClick={resubmit}
      />
      <Flex
        className={cnUnverifiedEmail('Buttons')}
        align="center"
        justify="center"
        gap="var(--space-m)"
        wrap="wrap"
      >
        <Button
          width="full"
          className={cnUnverifiedEmail('Button', { alert: true })}
          label={t('unverified_email.exit')?.toString()}
          size="l"
          onClick={onCancel}
        />
      </Flex>
    </Flex>
  );
};

import React, { useEffect } from 'react';
import { useAction } from '@reatom/npm-react';
import { useAccounts } from '##/hooks/useAccounts';
import { handleError } from '##/utils/errorsConverter';
import { getOauth2Providers } from '##/api/actions/oauth2';
import { providersAtom } from '##/atoms/providers';

export const DataWrapper = ({ children }: { children?: React.ReactNode }) => {
  const setProviders = useAction(providersAtom);

  useAccounts();

  const loadProviders = async () => {
    try {
      const {
        data: { data },
      } = await getOauth2Providers();
      setProviders(data);
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    loadProviders();
  }, []);

  return <>{children}</>;
};

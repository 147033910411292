import React, { useEffect } from 'react';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { useTranslation } from 'react-i18next';
import { Text } from '@consta/uikit/Text';
import { useAtom } from '@reatom/npm-react';
import { Button } from '@consta/uikit/Button';
import { mfaModeAtom } from '##/atoms/mfa';
import { cn } from '##/utils/bem';
import './MFASuccess.css';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { Flex } from '##/components/Layout/Flex';

const cnMFASuccess = cn('MFASuccess');

export const MFASuccess = () => {
  const { t } = useTranslation();

  const [mfaMode] = useAtom(mfaModeAtom);

  const [_navigate, goToPath] = useAnchorNavigate();

  useEffect(() => {
    if (mfaMode === 'inside') {
      goToPath('/login', false);
    }
  }, [mfaMode]);

  return (
    <Flex
      direction="column"
      gap="var(--space-xl)"
      align="center"
      justify="center"
      className={cnMFASuccess(null, [cnMixSpace({ pV: '2xl', pH: 'xl' })])}
    >
      <Text as="h1" size="3xl" lineHeight="xs" weight="semibold">
        {t('mfa_success.title')}
      </Text>
      <Text as="p" size="m" lineHeight="m" align="center">
        {t(`mfa_success.description_${mfaMode}`)}
      </Text>
      <Button
        label={t('mfa_success.close').toString()}
        onClick={window.close}
      />
    </Flex>
  );
};

import { Modal } from '@consta/uikit/Modal';
import React, { useState } from 'react';
import './MfaConnectModal.css';
import { MfaConnectionModalSuggestion } from './MfaConnectionModalSuggestion/MfaConnectionModalSuggestion';
import { MfaConnectModalConnection } from './MfaConnectModalConnection/MfaConnectModalConnection';
import { cn } from '##/utils/bem';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
};

export const cnMfaConnectModal = cn('MfaConnectModal');

export const MfaConnectModal = (props: Props) => {
  const { isOpen, onClose } = props;

  const [stage, setStage] = useState<'suggestion' | 'connection'>('suggestion');

  return (
    <Modal
      isOpen={isOpen}
      className={cnMfaConnectModal({ stage })}
      rootClassName={cnMfaConnectModal('Root')}
    >
      {stage === 'suggestion' ? (
        <MfaConnectionModalSuggestion
          onClose={onClose}
          onContinue={() => setStage('connection')}
        />
      ) : (
        <MfaConnectModalConnection onClose={onClose} />
      )}
    </Modal>
  );
};

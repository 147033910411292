import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const getValidationMessages = (t: TFunction) => ({
  required: t('errors.required'),
  numeric: t('errors.numeric'),
  email: t('errors.email'),
  phone: t('errors.phone'),
  passwordRepeat: t('errors.passwordRepeat'),
});

export const REQUIRED = (t: TFunction) =>
  Yup.mixed().required(getValidationMessages(t).required);

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const EMAIL = (t: TFunction) =>
  Yup.string()
    .required(getValidationMessages(t).required)
    .test(
      'email',
      getValidationMessages(t).email,
      (value) =>
        (value || '').search(/[\s\p{sc=Cyrillic}]/u) === -1 &&
        emailRegex.test(value || ''),
    );

export const REQUIRED_CHECKBOX = (t: TFunction) => (name: string) =>
  Yup.boolean().test(
    name,
    getValidationMessages(t).required,
    (value) => !!value,
  );

export const REGEXP_TEST =
  (t: TFunction) => (name: string, regexp: RegExp, message: string) =>
    Yup.string()
      .required(getValidationMessages(t).required)
      .test(name, message, (value) => regexp.test(value as string));

export const ONLY_NUMBERS = (t: TFunction) =>
  Yup.number()
    .required(getValidationMessages(t).required)
    .typeError(getValidationMessages(t).numeric);

export const PHONE = (t: TFunction) =>
  Yup.string().test('phone', getValidationMessages(t).phone, (value) =>
    /^((\+7|7|8)(\s|-|\()?\d{3}(\s|-|\))?([0-9\-\s]){7,10})$/.test(
      value as string,
    ),
  );

export const POSITIVE_NUMBER_SRTING = (t: TFunction) =>
  Yup.string()
    .nullable()
    .test(
      'Is positive?',
      t('errors.numberPositive')?.toString(),
      (value) => !value || (value ? Number(value) : 0) > 0,
    );

export const POSITIVE_NUMBER = (t: TFunction) =>
  Yup.number()
    .nullable()
    .test(
      'Is positive?',
      t('errors.numberPositive')?.toString(),
      (value) => !value || (value ?? 0) > 0,
    );

export const STRING_LENGTH =
  (t: TFunction) =>
  (min: number | undefined, max: number | undefined, trim?: boolean) =>
    Yup.string()
      .nullable()
      .test(
        'String Length',
        `${t('errors.stringLength.length')}${
          min ? ` ${t('other.from')} ${min}` : ''
        }${max ? ` ${t('other.to')} ${max}` : ''} ${t(
          'errors.stringLength.symbols',
        )}`,
        (str) => {
          let val = str;
          if (trim) {
            val = val?.trim();
          }

          return (
            !val ||
            !!(
              val &&
              (!min || val.length >= min) &&
              (!max || val.length <= max)
            )
          );
        },
      );

export const CONFIRM =
  (t: TFunction) =>
  (name = 'password') =>
    Yup.string()
      .required(getValidationMessages(t).required)
      .oneOf([Yup.ref(name), null], getValidationMessages(t).passwordRepeat);

export const specialSymbolsRegex =
  // eslint-disable-next-line
  /[\+\\\×\÷\=\/\_\€\£\¥\₽\!\@\#\$\%\&\^\*\(\)\\'\:\;\,\?\`\~\\|\<\>\{\}\[\]\°\•\○\●\□\■\♤\♡\◇\♧\☆\▪︎\¤\《\》\¡\¿\.\±\§]/g;

export const cyrillicRegex = /[а-яё]/i;
export const latinRegex = /[a-z]/i;

export const isUserInitialsValid = (val?: string) => {
  if (!val) return false;

  const hasCyrillic = cyrillicRegex.test(val);
  const hasLatin = latinRegex.test(val);
  if (hasCyrillic && hasLatin) return false;

  const words = val.trim().split(/\s+/).filter(Boolean);

  if (words.length < 2) return false;

  for (const str of words) {
    if (!/^[-a-zа-яё]+$/i.test(str)) return false;
  }

  return true;
};

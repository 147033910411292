import React, { useEffect, useState } from 'react';
import './Registration.css';
import { Formik, Form } from 'formik';
import { Text } from '@consta/uikit/Text';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Button } from '@consta/uikit/Button';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '@consta/uikit/useBreakpoints';
import { cnMixAuth } from '##/mixs/MixAuth';
import { FormikCheckbox } from '##/components/controls/formik/FormikCheckbox';
import { FormikInput } from '##/components/controls/formik/FormikInput';
import { useForm } from './hooks/useForm';
import { cn } from '##/utils/bem';
import { useRequest } from './hooks/useRequest';
import { ActionModal } from '##/components/ActionModal';
import { useDebounce } from '##/hooks/useDebounce';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { Providers } from '##/components/Providers';

const cnRegistration = cn('Registration');

export const Registration = () => {
  const { initialValues, schema } = useForm();

  const { t } = useTranslation();

  const { isTablet } = useBreakpoints({ isTablet: 700 });

  const [code, setCode] = useState<string | null>(null);

  const debouncedCode = useDebounce(code, 300);

  const {
    isLoading,
    onSubmit,
    isOpen,
    onClose: onCloseProp,
    userEmail,
    loadSponsorName,
    seconds,
    sponsorName,
    clearSponsor,
  } = useRequest();

  useEffect(() => {
    if (debouncedCode) {
      loadSponsorName(debouncedCode);
    } else {
      clearSponsor();
    }
  }, [debouncedCode]);

  const [navigate] = useAnchorNavigate();

  const onClose = (e: React.MouseEvent) => {
    navigate('/unverified_email?suggest_mfa=true')(e);
    onCloseProp();
  };

  return (
    <div
      className={cnRegistration(null, [
        cnMixAuth(null, { withBottomContainer: true }),
      ])}
    >
      <ActionModal
        isOpen={isOpen}
        onClose={onClose}
        title={t('registration.modal.title')?.toString()}
        message={t('registration.modal.message', {
          email: userEmail,
        })?.toString()}
        buttonText={t('registration.modal.button')?.toString()}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}
      >
        {({ values, setFieldValue }) => (
          <Form
            className={cnRegistration('Container', [
              cnMixAuth('Container', null),
            ])}
          >
            <Text
              as="h1"
              size="3xl"
              lineHeight="xs"
              weight="semibold"
              className={cnRegistration('Title')}
            >
              {t('registration.title')}
            </Text>
            <Grid
              cols={isTablet ? 2 : 1}
              rowGap="m"
              colGap="l"
              className={cnRegistration('Grid')}
            >
              <GridItem col={1} row={1}>
                <FormikInput
                  name="username"
                  type="text"
                  required
                  size="l"
                  autoComplete="username"
                  disabled={isLoading}
                  width="full"
                  placeholder={t(
                    'registration.inputs.login.placeholder',
                  )?.toString()}
                  label={t('registration.inputs.login.label')?.toString()}
                />
              </GridItem>
              <GridItem col={1} row={1}>
                <FormikInput
                  name="email"
                  type="text"
                  required
                  size="l"
                  autoComplete="email"
                  disabled={isLoading}
                  width="full"
                  placeholder={t(
                    'registration.inputs.email.placeholder',
                  )?.toString()}
                  label={t('registration.inputs.email.label')?.toString()}
                />
              </GridItem>
              <GridItem col={1} row={1}>
                <FormikInput
                  name="password"
                  type="password"
                  required
                  autoComplete="new-password"
                  size="l"
                  disabled={isLoading}
                  width="full"
                  placeholder={t(
                    'registration.inputs.password.placeholder',
                  )?.toString()}
                  label={t('registration.inputs.password.label')?.toString()}
                />
              </GridItem>
              <GridItem col={1} row={1}>
                <FormikInput
                  name="re_password"
                  type="password"
                  required
                  disabled={isLoading}
                  autoComplete="new-password"
                  size="l"
                  width="full"
                  placeholder={t(
                    'registration.inputs.re_password.placeholder',
                  )?.toString()}
                  label={t('registration.inputs.re_password.label')?.toString()}
                />
              </GridItem>
              <div className={cnRegistration('GridDivider')} />
              <GridItem col={1} row={1}>
                <FormikInput
                  name="partner_code"
                  type="text"
                  status={sponsorName === '$error_name' ? 'alert' : undefined}
                  caption={
                    sponsorName
                      ? sponsorName === '$error_name'
                        ? t('registration.sponsor.not_found')?.toString()
                        : t('registration.sponsor.name', {
                            sponsorName,
                          })?.toString()
                      : undefined
                  }
                  onChange={({ value }) => {
                    setCode(value);
                    setFieldValue('partner_code', value);
                  }}
                  placeholder={t(
                    'registration.inputs.partner_code.placeholder',
                  )?.toString()}
                  label={t(
                    'registration.inputs.partner_code.label',
                  )?.toString()}
                  size="l"
                  disabled={isLoading || !!initialValues.partner_code}
                  width="full"
                />
              </GridItem>
              <GridItem col={1} row={1}>
                <FormikInput
                  name="name"
                  type="text"
                  placeholder={t(
                    'registration.inputs.name.placeholder',
                  )?.toString()}
                  label={t('registration.inputs.name.label')?.toString()}
                  required
                  size="l"
                  disabled={isLoading}
                  width="full"
                />
              </GridItem>
            </Grid>
            <FormikCheckbox
              name="user_agreement"
              size="m"
              disabled={isLoading}
              className={cnRegistration('Agreement')}
              // @ts-ignore
              label={
                <>
                  {t('registration.confirm')}{' '}
                  <a
                    target="_blank"
                    href="https://docs.google.com/document/d/1IZ8tLdwLpGiJmxUdPtV66xdPEvUf8w6pLGxthINIaSQ/edit"
                    rel="noreferrer"
                  >
                    {t('registration.rules')}
                  </a>{' '}
                  {t('other.and')}{' '}
                  <a
                    target="_blank"
                    href="https://docs.google.com/document/d/1IZ8tLdwLpGiJmxUdPtV66xdPEvUf8w6pLGxthINIaSQ/edit"
                    rel="noreferrer"
                  >
                    {t('registration.agreement')}
                  </a>
                </>
              }
            />
            <Button
              size="l"
              width="full"
              disabled={!values.user_agreement || seconds !== 0}
              loading={isLoading}
              type="submit"
              label={
                seconds > 0
                  ? `${t('other.resubmit', { seconds })}`
                  : t('registration.button')?.toString()
              }
            />
            <Providers label={t('registration.providers')?.toString()} />
          </Form>
        )}
      </Formik>
      <Text
        className={cnRegistration('Additional', [
          cnMixAuth('Additional', null),
        ])}
        size="m"
        lineHeight="s"
        weight="light"
        href="/login"
        as="a"
        onClick={navigate('/login')}
      >
        {t('registration.has_account')}
        <Text as="p" weight="semibold" view="link">
          {t('registration.sign_in')}
        </Text>
      </Text>
    </div>
  );
};

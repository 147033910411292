import { IaaAction } from '##/types/iaa';
import { OauthIdentity } from '##/types/oauth';
import { MfaRetryAction } from './actions/mfa';

export default {
  iaa: {
    executeAction: (action: IaaAction, id: string) => `/iaa/${action}/${id}/`,
    getIsCompleted: (id: string) => `/iaa/${id}/is-completed/`,
    default: '/iaa/default/',
  },
  users: {
    authenticate: '/users/token/',
    refresh: '/users/token/refresh/',
    inSesion: '/users/in-sessions/',
    info: '/users/info/',
    profile: '/users/profile/',
    logout: `/users/logout/`,
    isLogged: (iaa?: string) => `/users/is_logged/${iaa ? `${iaa}/` : ''}`,
    recovery: (iaa: string) => `/users/send/mail/${iaa}`,
    whois: '/users/whois/',
    resend_mail: (iaa: string) =>
      `/users/send/mail/${iaa}/?action=send_activation`,
    forget: '/users/forget/',
    sponsor: '/users/sponsor/',
  },
  mfa: {
    connect: '/mfa/connect/',
    disconnect: '/mfa/disconnect/',
    approve: '/mfa/disconnect/approve/',
    retry: (action: MfaRetryAction) => `/mfa/retry/${action}/`,
    allow: '/mfa/allow/',
  },
  oauth2: {
    authorize: (identity: OauthIdentity, id: string) =>
      `/oauth2/${identity}/${id}/authorize`,
    callback: (identity: OauthIdentity, search?: string) =>
      `/oauth2/${identity}/callback${search}`,
    availableProviders: `/oauth2/available-providers/`,
  },
};

import { IconComponent } from '@consta/icons/Icon';
import React from 'react';
import { MfaType } from '##/types/mfa';

export const inputTypeMap: Record<MfaType, React.HTMLInputTypeAttribute> = {
  email: 'email',
  sms: 'number',
  google_totp: 'text',
};

export const types: Array<{
  icon?: IconComponent | string;
  label: string;
  type: MfaType;
}> = [
  {
    label: 'Email',
    icon: '@',
    type: 'email',
  },
  //   {
  //     label: 'SMS',
  //     type: 'sms',
  //   },
  //   {
  //     label: 'Google 2FA',
  //     type: 'google_totp',
  //   },
];

export const validateEmail = (value: string | null, hard?: boolean) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (hard && value && regex.test(value)) return true;
  if (!hard) {
    if (!value) {
      return true;
    }
    if (/^[a-z0-9_.-]+$/.test(value)) return true;
    if (/^[a-z0-9_.-]+@$/.test(value)) return true;
    if (/^[a-z0-9_.-]+@[a-z0-9-]+$/.test(value)) return true;
    if (/^[a-z0-9_.-]+@[a-z0-9-]+\.$/.test(value)) return true;
    if (/^[a-z0-9_.-]+@[a-z0-9-]+\.[a-z]{1,4}$/.test(value)) return true;
    if (/^[a-z0-9_.-]+@[a-z0-9-]+\.[a-z]{1,4}\.$/.test(value)) return true;
    if (/^[a-z0-9_.-]+@[a-z0-9-]+\.[a-z]{1,4}\.[a-z]{1,4}$/.test(value))
      return true;
  }
  return false;
};

const mails = ['mail', 'gmail', 'yandex', 'outlook', 'yahoo'];
const domains = ['com', 'ru', 'co', 'kz', 'ua', 'ru.com', 'net'];

export const validators: Record<
  MfaType,
  (value: string | null, flag?: boolean) => boolean
> = {
  email: validateEmail,
  sms: () => true,
  google_totp: () => true,
};

export const getAutoCompleteList = (value: string | null) => {
  if (!value) {
    return [];
  }
  if (/@[a-z]{1,}\./gm.test(value)) {
    return domains.map((domain) => `${value.split('.')[0]}.${domain}`);
  }
  if (
    /^[a-z0-9_.-]+$/.test(value) ||
    /^[a-z0-9_.-]+@$/.test(value) ||
    /^[a-z0-9_.-]+@[a-z0-9-]+$/.test(value)
  ) {
    return mails.map((mail) => `${value.split('@')[0]}@${mail}`);
  }
  return [];
};

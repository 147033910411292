import React, { useEffect, useMemo, useState } from 'react';
import { ResponsesExit } from '@consta/uikit/ResponsesExit';
import './MFA.css';
import { Text } from '@consta/uikit/Text';
import QRCode from 'react-qr-code';
import { useAction, useAtom } from '@reatom/npm-react';
import { Button } from '@consta/uikit/Button';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { useFlag } from '@consta/uikit/useFlag';
import { TextFieldPropOnChange } from '@consta/uikit/TextField';
import { AutoComplete } from '@consta/uikit/AutoCompleteCanary';
import { useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { useTranslation } from 'react-i18next';
import { mfaModeAtom, mfaTypeAtom, mfaUserAuthValueAtom } from '##/atoms/mfa';
import { cn } from '##/utils/bem';
import {
  getAutoCompleteList,
  inputTypeMap,
  validators,
} from '##/components/MfaConnectModal/MfaConnectModalConnection/helper';
import { Flex } from '##/components/Layout/Flex';
import { getMfaAllow, mfaConnect, mfaDisconnect } from '##/api/actions/mfa';
import { ServerResponse } from '##/types/common';
import { MfaType } from '##/types/mfa';
import { accountIdAtom } from '##/atoms/accounts';
import { handleError } from '##/utils/errorsConverter';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { writeConsoleMessage } from '##/utils/writeConsoleMessage';
import { useTimer } from '##/hooks/useTimer';
import { clearUuid } from '##/atoms/uuid';
import { useUuid } from '##/hooks/useUuid';

type Props = {
  mode: 'connect' | 'disconnect';
};

const cnMFA = cn('MFA');

export const MFA = ({ mode }: Props) => {
  const [type, setType] = useAtom(mfaTypeAtom);
  const [value, setValue] = useAtom(mfaUserAuthValueAtom);
  const [qr, setQr] = useState<string | undefined>();

  const [isLoading, setIsLoading] = useFlag();
  const [error, setError] = useState<string | null>(null);
  const [submitted, setIsSubmitted] = useFlag();
  const [showProviders, setShowProviders] = useFlag(true);
  const [uuid] = useUuid();
  const { t } = useTranslation();

  const [navigate, goToPath] = useAnchorNavigate();

  const setMfaMode = useAction(mfaModeAtom);

  const [searchParams] = useSearchParams();

  const [user_id, setUserId] = useAtom(accountIdAtom);

  const { seconds, initTimer } = useTimer();

  const userId = searchParams.get('user_id');
  // @ts-ignore
  const token: string | undefined = searchParams.get('token');

  const handleChange: TextFieldPropOnChange = ({ value }) => {
    if (validators[type](value, false)) {
      setValue(value);
    }
  };

  const checkUserId = async (userId: number) => {
    try {
      setIsLoading.on();
      const {
        data: { data },
      } = await getMfaAllow(userId, mode === 'connect', token);
      setUserId(userId);
      if (mode === 'disconnect') {
        setShowProviders.off();
        data.multifactor_provider && setType(data.multifactor_provider);
      }
    } catch (e) {
      const {
        data: { data },
        status,
      } = e as AxiosResponse<ServerResponse<{ url: string }>>;
      if (status === 418 && data.url) {
        clearUuid();
        goToPath(data.url, false, { target: '_self' });
      } else {
        goToPath('/login', false);
        writeConsoleMessage(e);
      }
    } finally {
      setIsLoading.off();
    }
  };

  const { activeType, items } = useMemo(() => {
    const items: Array<{ type: MfaType; label: string }> = [
      {
        type: 'email',
        label: 'Email',
      },
      {
        type: 'google_totp',
        label: 'Google TOTP',
      },
    ];
    return {
      items,
      activeType: items.find((el) => el.type === type),
    };
  }, [type]);

  const onError = (e: AxiosResponse<ServerResponse>) => {
    const {
      error: message,
      time,
      code,
    } = handleError(e, {
      defaultText:
        e.status === 401 ? t('mfa.errors.unauthorized').toString() : undefined,
    });
    if (code === 10) {
      initTimer(Number(time));
    }
    if (message) {
      setError(message);
    }
  };

  const onContinue = (e: React.MouseEvent) => {
    navigate(`/multi_factor?token=${token}`)(e);
  };

  const onSubmit = async (e: React.MouseEvent) => {
    try {
      if (validators[type](value, true)) {
        setIsLoading.on();
        setError(null);
        if (mode === 'connect' && type === 'email') {
          await mfaConnect(
            'email',
            { email: value ?? '', user_id },
            uuid,
            token,
          );
          onContinue(e);
        }
        if (mode === 'connect' && type === 'google_totp') {
          const { data } = await mfaConnect(
            'google_totp',
            { user_id },
            uuid,
            token,
          );
          setQr(data.data.data.qr_uri);
        }
        if (mode === 'disconnect') {
          await mfaDisconnect(uuid, user_id, value ?? '', token);
          onContinue(e);
        }
        setIsSubmitted.on();
      } else {
        setError(t('mfa.errors.format'));
      }
    } catch (e) {
      onError(e as AxiosResponse<ServerResponse>);
    } finally {
      setIsLoading.off();
    }
  };

  useEffect(() => {
    mode && setMfaMode(mode);
  }, [mode]);

  useEffect(() => {
    if (userId) {
      checkUserId(Number(userId));
    }
  }, [userId]);

  return (
    <Flex
      className={cnMFA(null, [cnMixSpace({ pV: '3xl', pH: '2xl' })])}
      direction="row"
      align="flex-start"
      justify="flex-start"
      gap="var(--space-4xl)"
    >
      <ResponsesExit className={cnMFA('Image')} />
      <Flex
        className={cnMFA('Wrapper')}
        direction="column"
        align="flex-start"
        justify="flex-start"
      >
        <Text
          className={cnMixSpace({ mB: '2xl' })}
          size="2xl"
          as="h1"
          weight="semibold"
        >
          {t(`mfa.titles.${mode}`)}
        </Text>
        <div className={cnMFA('Controls')}>
          {showProviders && (
            <ChoiceGroup
              width="full"
              value={activeType}
              items={items}
              name="mfaType"
              className={cnMixSpace({ mB: 'xl' })}
              onChange={({ value }) => setType(value.type)}
            />
          )}
          {type === 'email' && (
            <AutoComplete
              value={value}
              items={getAutoCompleteList(value)}
              getItemLabel={(item) => item}
              getItemKey={(item) => item}
              disabled={isLoading}
              onChange={handleChange}
              type={inputTypeMap[type]}
              placeholder="email@email.com"
              caption={error ?? undefined}
              status={error ? 'alert' : undefined}
              width="full"
            />
          )}
          {type === 'google_totp' && submitted && mode === 'connect' && qr && (
            <Flex className={cnMFA('QR')} align="center" justify="center">
              <QRCode value={qr} />
            </Flex>
          )}
          <Button
            className={cnMixSpace({ mT: 'xl' })}
            onClick={
              submitted && type === 'google_totp' ? onContinue : onSubmit
            }
            disabled={seconds !== 0}
            loading={isLoading}
            label={
              seconds > 0
                ? `${t('other.resubmit', { seconds })}`
                : t(
                    `mfa.buttons.${
                      submitted && type === 'google_totp' && mode === 'connect'
                        ? 'continue'
                        : mode
                    }`,
                  )?.toString()
            }
            width="full"
          />
        </div>
      </Flex>
    </Flex>
  );
};

import Axios, { AxiosError } from 'axios';
import { DOMAIN } from '##/utils/env';
import { getDefaultLanguage } from '##/atoms/language';

export const instance = Axios.create({
  baseURL: `${DOMAIN}`,
  headers: {
    'Content-Type': 'application/json',
    'ACCEPT_LANGUAGE': getDefaultLanguage(),
  },
  withCredentials: true,
});

export const setAuthToken = (token: string) => {
  instance.defaults.headers.Authorization = `Bearer ${token}`;
};

export const deleteAuthHeader = () => {
  delete instance.defaults.headers?.Authorization;
};

instance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest: typeof error.config & { _retry?: boolean } =
      error.config ?? {};

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
    }

    return Promise.reject(error.response);
  },
);

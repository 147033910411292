import { useFlag } from '@consta/uikit/useFlag';
import { oauth2Authorize } from '##/api/actions/oauth2';
import { useUuid } from '##/hooks/useUuid';
import { OauthIdentity } from '##/types/oauth';
import { handleError } from '##/utils/errorsConverter';

export const useRequest = () => {
  const [uuid] = useUuid();
  const [isLoading, setIsLoading] = useFlag();

  const authorize = async (identity: OauthIdentity) => {
    try {
      setIsLoading.on();
      const {
        data: { data },
      } = await oauth2Authorize(identity, uuid);
      window.open(data.url, '_self');
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading.off();
    }
  };

  return {
    authorize,
    isLoading,
  };
};

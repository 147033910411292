import React, { useMemo, useRef } from 'react';
import './AccountsCard.css';
import { PropsWithHTMLAttributes } from '@consta/uikit/__internal__/src/utils/types/PropsWithHTMLAttributes';
import { Avatar } from '@consta/uikit/Avatar';
import { Text } from '@consta/uikit/Text';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { IconCheck } from '@consta/icons/IconCheck';
import { Button } from '@consta/uikit/Button';
import { IconKebab } from '@consta/icons/IconKebab';
import { useFlag } from '@consta/uikit/useFlag';
import { withTooltip } from '@consta/uikit/withTooltip';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { useTranslation } from 'react-i18next';
import { cn } from '##/utils/bem';
import { UserAccount } from '##/types/users';
import { Flex } from '##/components/Layout/Flex';
import {
  logout as logoutRequest,
  forget as forgetRequest,
} from '##/api/actions/users';
import { isStatusSuccess } from '##/utils/requests';
import { handleError } from '##/utils/errorsConverter';

const cnAccountsCard = cn('AccountsCard');

type Props = PropsWithHTMLAttributes<
  { account: UserAccount; onLogout?: (forgot?: boolean) => void },
  HTMLDivElement
>;

const Badge = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cnAccountsCard('Badge', [className])} {...props}>
    <IconCheck size="xs" view="success" />
  </div>
));

export const AccountsCard = (props: Props) => {
  const { account, className, onLogout, ...otherProps } = props;

  const { avatar, identity, sub_identity, email_verified, already_logged } =
    account;

  const [showMenu, setShowMenu] = useFlag();
  const [isLoading, setIsLoading] = useFlag();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const logout = async (e: React.MouseEvent, forget?: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setIsLoading.on();
      const { status } = await (forget ? forgetRequest : logoutRequest)(
        account.id,
      );
      if (isStatusSuccess(status)) {
        onLogout?.(forget);
      }
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading.off();
    }
  };

  const { t } = useTranslation();

  const items = useMemo(() => {
    const arr: Array<{ label: string; onClick: React.MouseEventHandler }> = [];
    arr.push({
      label: t('accounts.menu.forgot_me'),
      onClick: (e: React.MouseEvent) => logout(e, true),
    });
    if (already_logged) {
      arr.push({
        label: t('accounts.menu.logout'),
        onClick: logout,
      });
    }
    return arr;
  }, [already_logged]);

  const onButtonClick: React.MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMenu.on();
  };

  const EmailVerified = withTooltip({ content: t('accounts.email_verified') })(
    Badge,
  );

  return (
    <>
      <Flex
        className={cnAccountsCard(null, [cnMixSpace({ pV: '2xs', pH: 'xs' })])}
        align="center"
        justify="space-between"
        gap="var(--space-m)"
        {...otherProps}
      >
        <div className={cnAccountsCard('Wrapper')}>
          <div className={cnAccountsCard('Avatar')}>
            <Avatar size="l" name={identity} url={avatar?.url} />
            {/* @ts-ignore */}
            {email_verified && <EmailVerified />}
          </div>
          <div className={cnAccountsCard('Info')}>
            <Text
              as="p"
              size="m"
              lineHeight="xs"
              weight="semibold"
              className={cnAccountsCard('Username')}
            >
              {identity}
            </Text>
            <Text
              as="p"
              size="s"
              lineHeight="xs"
              view="secondary"
              className={cnAccountsCard('Email')}
            >
              {sub_identity}
            </Text>
          </div>
        </div>
        {items.length > 0 && (
          <Button
            form="round"
            view="clear"
            size="s"
            ref={buttonRef}
            loading={isLoading}
            iconLeft={IconKebab}
            onClick={onButtonClick}
          />
        )}
      </Flex>
      <ContextMenu
        isOpen={showMenu}
        onClickOutside={setShowMenu.off}
        getItemOnClick={(item) =>
          ({ e }) =>
            item.onClick(e)}
        items={items}
        style={{
          zIndex: 20,
        }}
        anchorRef={buttonRef}
      />
    </>
  );
};

import React from 'react';
import './Recovery.css';
import { Text } from '@consta/uikit/Text';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Button } from '@consta/uikit/Button';
import { cn } from '##/utils/bem';
import { cnMixAuth } from '##/mixs/MixAuth';
import { FormikInput } from '##/components/controls/formik/FormikInput';
import { useForm } from './hooks/useForm';
import { useRequest } from './hooks/useRequest';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { ActionModal } from '##/components/ActionModal';

const cnRecovery = cn('Recovery');

export const Recovery = () => {
  const { t } = useTranslation();

  const [navigate] = useAnchorNavigate();

  const { initialValues, schema } = useForm();

  const {
    isLoading,
    onSubmit,
    seconds,
    isEmailSent,
    handleCloseEmailSentModal,
  } = useRequest();

  return (
    <div
      className={cnRecovery(null, [
        cnMixAuth(null, { withBottomContainer: true }),
      ])}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
      >
        {() => (
          <Form
            className={cnRecovery('Container', [cnMixAuth('Container', null)])}
            noValidate
          >
            <Text
              as="h1"
              size="3xl"
              lineHeight="xs"
              align="center"
              weight="semibold"
              className={cnRecovery('Title')}
            >
              {t('recovery.title')}
            </Text>
            <FormikInput
              name="email"
              type="email"
              disabled={isLoading}
              autoComplete="email"
              size="l"
              width="full"
              placeholder={t('recovery.inputs.email.placeholder')?.toString()}
            />
            <Button
              size="l"
              width="full"
              type="submit"
              loading={isLoading}
              disabled={seconds !== 0}
              label={
                seconds > 0
                  ? `${t('other.resubmit', { seconds })}`
                  : t('recovery.button')?.toString()
              }
            />
          </Form>
        )}
      </Formik>
      <Button
        view="clear"
        label={t('recovery.sign_in')?.toString()}
        onClick={navigate('/login')}
        as="a"
        href="/login"
        className={cnRecovery('Additional', [cnMixAuth('Additional', null)])}
      />
      <ActionModal
        isOpen={isEmailSent}
        onClose={handleCloseEmailSentModal}
        title={t('recovery.email_sent_modal.title')?.toString()}
        message={t('recovery.email_sent_modal.message')?.toString()}
        buttonText={t('recovery.email_sent_modal.button')?.toString()}
      />
    </div>
  );
};

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAction, useAtom } from '@reatom/npm-react';
import { OauthIdentity } from '##/types/oauth';
import { oauth2Callback } from '##/api/actions/oauth2';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { defaultRedirectAtom, redirectUrlAtom } from '##/atoms/application';
import { accountIdAtom } from '##/atoms/accounts';
import { clearUuid } from '##/atoms/uuid';

export const ProviderCallback = () => {
  const { provider } = useParams() as { provider: OauthIdentity };
  const setAccountId = useAction(accountIdAtom);
  const [defaultUrl] = useAtom(defaultRedirectAtom);
  const setUrl = useAction(redirectUrlAtom);

  const [_navigate, goToPath] = useAnchorNavigate();

  const postCallback = async (provider: OauthIdentity) => {
    const {
      data: { data },
    } = await oauth2Callback(
      provider,
      `${window.location.search}&redirect_url=${defaultUrl}`,
    );
    if (data.type === 'common' || data.type === 'mfa') {
      // if (
      //   data.type === 'common' &&
      //   data.conflicts?.includes('oauth2_sponsor_not_setted')
      // ) {
      //   setAccountId(data.user_id); // ставим, так как юзер ид требуется в запросе на установку спонсора
      //   goToPath('/oauth2/sponsor', false);
      //   return;
      // }
      clearUuid();
      goToPath(data.url, false, { target: '_self' });
    } else if (data.reason === 'oauth2_not_setted') {
      setAccountId(data.user_id); // ставим, так как юзер ид требуется в запросе на установку спонсора
      goToPath('/oauth2/sponsor', false);
    }
  };

  useEffect(() => {
    if (provider) {
      postCallback(provider);
    }
  }, [provider]);

  return null;
};

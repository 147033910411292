import { useState, useEffect } from 'react';

export const useOrientation = (): boolean => {
  const [isPortrait, setIsPortrait] = useState<boolean>(
    window.matchMedia('(orientation: portrait)').matches,
  );

  useEffect((): (() => void) => {
    const handleOrientationChange = (event: MediaQueryListEvent): void => {
      setIsPortrait(event.matches);
    };

    const orientationMediaQuery = window.matchMedia('(orientation: portrait)');
    orientationMediaQuery.addEventListener('change', handleOrientationChange);

    return (): void => {
      orientationMediaQuery.removeEventListener(
        'change',
        handleOrientationChange,
      );
    };
  }, []);

  return isPortrait;
};

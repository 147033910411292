import { AxiosPromise } from 'axios';
import { MfaType } from '##/types/mfa';
import { instance } from '..';
import endpoints from '../endpoints';
import { ServerResponse } from '##/types/common';

export type MfaRetryAction = 'connect_user' | 'disconnect_user' | 'approve';

type Response<TYPE extends MfaType> = {
  identifier: string | null;
  status: boolean;
  provider: MfaType;
  type: 'mfa';
} & (TYPE extends 'google_totp'
  ? {
      data: {
        qr_uri: string;
      };
    }
  : {});

export const mfaConnect = <TYPE extends MfaType>(
  identity: TYPE,
  params: TYPE extends 'email'
    ? { email: string; user_id?: number }
    : { email?: string; user_id?: number },
  uuid: string,
  token?: string,
) =>
  instance.post<ServerResponse<Response<TYPE>>>(
    endpoints.mfa.connect,
    { email: params.email },
    {
      params: {
        identity,
        user_id: params.user_id,
        iaa_id: uuid,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const mfaRetry = (
  action: MfaRetryAction,
  uuid: string,
  user_id?: number,
  token?: string,
) =>
  instance.post<ServerResponse>(
    endpoints.mfa.retry(action),
    {},
    {
      params: {
        user_id,
        iaa_id: uuid,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const mfaDisconnect = (
  uuid: string,
  user_id?: number,
  email?: string,
  token?: string,
) =>
  instance.post<ServerResponse>(
    endpoints.mfa.disconnect,
    {
      email,
    },
    {
      params: {
        user_id,
        iaa_id: uuid,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const mfaApproveDisconnect = (
  uuid: string,
  code: string,
  user_id?: number,
  token?: string,
) =>
  instance.post<ServerResponse>(
    endpoints.mfa.approve,
    { code },
    {
      params: { user_id, iaa_id: uuid },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const getMfaAllow = (
  user_id: number,
  is_connect: boolean,
  token?: string,
) =>
  instance.get<
    ServerResponse<{
      id: number;
      username: string;
      multifactor_activated: boolean;
      multifactor_provider: MfaType;
    }>
  >(endpoints.mfa.allow, {
    params: {
      user_id,
      is_connect,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

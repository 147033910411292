import { useAction, useAtom } from '@reatom/npm-react';
import { useFlag } from '@consta/uikit/useFlag';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserAccount } from '##/types/users';
import {
  accountIdAtom,
  accountIdentityAtom,
  accountsAtom,
} from '##/atoms/accounts';
import { executeIaaAction } from '##/api/actions/iaa';
import { useUuid } from '##/hooks/useUuid';
import { isStatusSuccess } from '##/utils/requests';
import { defaultRedirectAtom } from '##/atoms/application';
import { handleError } from '##/utils/errorsConverter';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { useTimer } from '##/hooks/useTimer';
import { oauth2Authorize } from '##/api/actions/oauth2';
import { OauthIdentity } from '##/types/oauth';
import { clearUuid } from '##/atoms/uuid';

export const useRequest = () => {
  const [defaultUrl] = useAtom(defaultRedirectAtom);

  const [navigate, goToPath] = useAnchorNavigate();

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useFlag();

  const [accounts, setAccounts] = useAtom(accountsAtom);

  const setAccountId = useAction(accountIdAtom);
  const setAccountIdentity = useAction(accountIdentityAtom);

  const [uuid] = useUuid();

  const { seconds, initTimer } = useTimer();

  const { logged, unlogged } = useMemo(() => {
    const logged: UserAccount[] = [];
    const unlogged: UserAccount[] = [];
    if (accounts && accounts?.forEach) {
      accounts.forEach((item) => {
        (item.already_logged ? logged : unlogged).push(item);
      });
    }
    return {
      logged,
      unlogged,
    };
  }, [accounts]);

  const login = async (user_id: number, defaultUuid?: string) => {
    try {
      const id = defaultUuid ?? uuid;
      setIsLoading.on();
      setAccountId(user_id);
      const {
        data: { data },
        status,
      } = await executeIaaAction(
        'autologin',
        id,
        {},
        { user_id, redirect_url: defaultUrl },
      );
      if (
        isStatusSuccess(status) &&
        (data.type === 'common' || data.type === 'mfa')
      ) {
        clearUuid();
        goToPath(data.url, false, { target: '_self' });
      }
    } catch (e) {
      const { code, time } = handleError(e);
      if (code === 10) {
        initTimer(Number(time));
      }
    } finally {
      setIsLoading.off();
    }
  };

  const oauthAuthorize = async (identity: OauthIdentity) => {
    try {
      setIsLoading.on();
      const {
        data: { data },
      } = await oauth2Authorize(identity, uuid);
      window.open(data.url, '_self');
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading.off();
    }
  };

  const onUnloggedClick = (account: UserAccount) => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const otherProviders = account.auth_providers.filter(
      (el) => el !== 'limid',
    );
    console.log(otherProviders);
    if (otherProviders.length > 0) {
      oauthAuthorize(otherProviders[0]);
    } else {
      setAccountId(account.id);
      setAccountIdentity(account.identity);
      navigate('/')(e);
    }
  };

  const onLoggedClick =
    (account: UserAccount) => async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (account.conflicts.includes('oauth2_sponsor_not_setted')) {
        setAccountId(account.id);
        goToPath('/oauth2/sponsor', false);
        return;
      }

      login(account.id);
      if (!account.email_verified) {
        goToPath('/unverified_email', false);
      }
    };

  const onLogout = (account: UserAccount) => (forget?: boolean) => {
    if (forget) {
      const newAccounts = accounts.filter((el) => el.id !== account.id);
      setAccounts(newAccounts);
      if (newAccounts.length === 0) {
        goToPath('/login', false);
      }
      return;
    }
    if (logged.find((el) => el.id === account.id)) {
      const copyAcc = { ...account };
      copyAcc.already_logged = false;
      setAccounts([...accounts.filter((el) => el.id !== account.id), copyAcc]);
    }
  };

  return {
    logged,
    unlogged,
    onLoggedClick,
    onUnloggedClick,
    onLogout,
    isLoading,
    seconds,
  };
};

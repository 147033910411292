import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import './WarningModal.css';
import { Button } from '@consta/uikit/Button';
import { cn } from '##/utils/bem';
import { cnMixAuth } from '##/mixs/MixAuth';
import '../OauthSponsor.css';

const cnOauthSponsor = cn('OauthSponsor');
const cnWarningModal = cn('WarningModal');

type Props = {
  onClose(): void;
  isOpen: boolean;
  onClick?: () => void;
};

export const WarningModal: FC<Props> = ({ onClose, isOpen, onClick }) => {
  const { t } = useTranslation();

  return (
    <Modal
      rootClassName={cnWarningModal('OauthModal')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={cnWarningModal('Wrapper')}>
        <Text
          as="h1"
          size="3xl"
          lineHeight="xs"
          weight="semibold"
          className={cnOauthSponsor('Title')}
        >
          {t('oauth_sponsor.title')}
        </Text>
        <Text
          as="p"
          size="m"
          lineHeight="xs"
          weight="semibold"
          className={cnWarningModal('Subtitle')}
        >
          {t('oauth_sponsor.modal.title')}
        </Text>
        <Button
          size="l"
          width="full"
          onClick={onClose}
          label={t('oauth_sponsor.modal.change_sponsor')?.toString()}
        />
      </div>
      <Text
        className={cnOauthSponsor('Additional', [
          cnMixAuth('Additional', null),
        ])}
        size="m"
        lineHeight="s"
        weight="light"
        as="p"
        onClick={onClick}
      >
        {t('oauth_sponsor.modal.continue')?.toString()}
      </Text>
    </Modal>
  );
};

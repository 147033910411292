import React from 'react';
import { useAtom } from '@reatom/npm-react';
import { Text } from '@consta/uikit/Text';
import { cnMixFocus } from '@consta/uikit/MixFocus';
import { PropsWithHTMLAttributes } from '##/types/PropsWithHTMLAttributes';
import { providersAtom } from '##/atoms/providers';
import { cn } from '##/utils/bem';
import './Providers.css';
import { Flex } from '../Layout/Flex';
import { useRequest } from './hooks/useRequest';

type Props = PropsWithHTMLAttributes<{ label?: string }, HTMLDivElement>;

const cnProviders = cn('Providers');

export const Providers = (props: Props) => {
  const { className, label, ...otherProps } = props;

  const [providers] = useAtom(providersAtom);

  const { authorize, isLoading } = useRequest();

  if (!providers || providers.length === 0) {
    return null;
  }

  return (
    <Flex
      direction="column"
      align="center"
      gap="var(--space-xs)"
      justify="center"
      className={cnProviders(null, [className])}
      {...otherProps}
    >
      {label && (
        <Text className={cnProviders('Label')} as="p" size="s">
          {label}
        </Text>
      )}
      <Flex
        align="center"
        justify="center"
        gap="var(--space-xs)"
        className={cnProviders('Container')}
      >
        {providers.map(({ icon, idenity }) => (
          <button
            key={idenity}
            className={cnProviders('Button', [cnMixFocus()])}
            disabled={isLoading}
            onClick={() => authorize(idenity)}
            type="button"
            title={idenity}
          >
            <img className={cnProviders('Image')} src={icon} alt={idenity} />
          </button>
        ))}
      </Flex>
    </Flex>
  );
};

import { atom } from '@reatom/core';
import { onUpdate } from '@reatom/hooks';
import { UserAccount } from '##/types/users';

const ACCOUNTS_KEY = 'magiclime-limid-accounts';

export const accountsAtom = atom<UserAccount[]>(
  (localStorage.getItem(ACCOUNTS_KEY) ?? []) as UserAccount[],
);

onUpdate(accountsAtom, (_ctx, value) => {
  localStorage.setItem(ACCOUNTS_KEY, JSON.stringify(value));
});

export const accountIdAtom = atom<number | undefined>(undefined);

export const accountIdentityAtom = atom<string | undefined>(undefined);

import React, { useEffect } from 'react';
import { Theme } from '@consta/uikit/Theme';
import { useAction, useAtom } from '@reatom/npm-react';
import { getLastPoint, useBreakpoints } from '@consta/uikit/useBreakpoints';
import { themeAtom } from '##/atoms/theme';
import { Dimension, dimensionAtom, dimensionMap } from '##/atoms/application';

type Props = { children?: React.ReactNode };

export const ThemeProvider = (props: Props) => {
  const { children } = props;

  const [theme] = useAtom(themeAtom);

  const setDimension = useAction((ctx, value: Dimension) =>
    dimensionAtom(ctx, value),
  );

  const deviceType = getLastPoint(useBreakpoints(dimensionMap));

  useEffect(() => {
    setDimension(deviceType ?? 'desktop');
  }, [deviceType]);

  return <Theme preset={theme}>{children}</Theme>;
};

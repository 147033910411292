export enum Language {
  RU = 'ru',
  EN = 'en_US',
  FA = 'fa',
}

export type AuthAction = 'login' | 'registration' | 'recovery';

export type AxiosError = {
  detail:
    | string
    | {
        loc: string[];
        type: string;
        msg: string;
      };
  request: string;
  scope: string;
  status: boolean;
};

type ServerError = {
  loc: string[];
  type: string;
  msg: string;
};

export type ServerResponse<T = unknown> = {
  success: boolean;
  message: string;
  error: number | ServerError[];
  data: T;
  meta: {
    url: string;
    method: string;
    version: string;
    server_time: string;
  };
};

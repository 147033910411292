import { onUpdate } from '@reatom/hooks';
import { reatomBoolean, reatomString } from '@reatom/primitives';
import Cookies from 'js-cookie';
import { atom } from '@reatom/core';
import { getDelayedDate } from '##/utils/date';

const uuid = Cookies.get('magiclime-sso-uuid');

export const uuidAtom = reatomString(uuid);

onUpdate(uuidAtom, (ctx, value) => {
  ctx.schedule(() =>
    Cookies.set('magiclime-sso-uuid', value, {
      expires: getDelayedDate({ minutes: 5 }),
    }),
  );
});

export const clearUuid = () => {
  Cookies.remove('magiclime-sso-uuid');
};

export const uuidCheckedAtom = reatomBoolean(false);

import React from 'react';
import { Modal } from '@consta/uikit/Modal';
import { useAtom } from '@reatom/npm-react';
import KinescopePlayer from '@kinescope/react-kinescope-player';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import { Gradient } from '##/components/Gradient';
import { cn } from '##/utils/bem';
import './PageWrapper.css';
import { ThemeName } from '##/assets/themes';
import { themeAtom } from '##/atoms/theme';
import { LanguageSelector } from '##/components/LanguageSelector';
import { dimensionAtom, isInitialLoadingAtom } from '##/atoms/application';
import { langaugeDirectionAtom } from '##/atoms/language';
import { PropsWithHTMLAttributes } from '##/types/PropsWithHTMLAttributes';
import { useOrientation } from '##/hooks/useOrientation';
import { Flex } from '../Layout/Flex';

type Props = PropsWithHTMLAttributes<
  { withVideo?: boolean; isLoading?: boolean; isLogicComponent?: boolean },
  HTMLDivElement
>;

const cnPageWrapper = cn('PageWrapper');

export const themeColorsMap: Record<ThemeName, string[]> = {
  ssoLight: ['#ffa832', '#00a600', '#4bd0a0', '#00a600'],
  ssoDark: ['#1c1d1f', '#f7f9fb', '#ad56fa', '#8264fc'],
};

export const PageWrapper = (props: Props) => {
  const {
    className,
    withVideo,
    isLoading: isLoadingProp,
    isLogicComponent,
    children,
    ...otherProps
  } = props;
  const [theme] = useAtom(themeAtom);
  const [dimension] = useAtom(dimensionAtom);
  const [langaugeDirection] = useAtom(langaugeDirectionAtom);
  const [isLoading] = useAtom(isInitialLoadingAtom);

  const isPortrait = useOrientation();

  const renderControls = () => (
    <Flex
      className={cnPageWrapper('Controls', { dimension })}
      align="center"
      justify="flex-end"
      gap="xl"
    >
      <LanguageSelector />
    </Flex>
  );

  return (
    <div className={cnPageWrapper({ dimension }, [className])} {...otherProps}>
      {dimension !== 'mobile' && renderControls()}
      <Gradient
        colors={themeColorsMap[theme.color.primary as ThemeName]}
        className={cnPageWrapper('Gradient')}
      />
      {isLoading || isLoadingProp ? (
        <div className={cnPageWrapper('Loader')}>
          <ProgressSpin className={cnPageWrapper('Spin')} />
          {isLogicComponent && children}
        </div>
      ) : (
        <Modal
          isOpen
          hasOverlay
          className={cnPageWrapper('Modal')}
          rootClassName={cnPageWrapper('Root')}
          style={{
            ['--text-direction' as string]: langaugeDirection,
          }}
        >
          {withVideo && (isPortrait || dimension === 'desktop') && (
            <KinescopePlayer
              className={cnPageWrapper('Player', [cnMixSpace({ mB: 'xl' })])}
              videoId="200853428"
            />
          )}
          <div className={cnPageWrapper('Content', { dimension, withVideo })}>
            {dimension === 'mobile' && renderControls()}
            {children}
          </div>
        </Modal>
      )}
    </div>
  );
};

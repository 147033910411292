import { atom } from '@reatom/core';
import { onUpdate } from '@reatom/hooks';
import { MfaType } from '##/types/mfa';

export const mfaUserAuthValueAtom = atom<string | null>(null);

export const mfaTypeAtom = atom<MfaType>('email');

export const mfaModeAtom = atom<'connect' | 'disconnect' | 'inside'>('inside');

onUpdate(mfaTypeAtom, (ctx) => {
  mfaUserAuthValueAtom(ctx, null);
});

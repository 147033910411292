import React, { useLayoutEffect, useState } from 'react';
import { reatomContext } from '@reatom/npm-react';
import { Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '##/components/ThemeProvider';
import { ctx } from '##/atoms/app';
import { AppRouter } from '##/components/AppRouter';
import './i18n';
import './App.css';
import { DataWrapper } from './components/DataWrapper';

export const browserHistory = createBrowserHistory();

export const App = () => {
  const [{ location, action }, setState] = useState({
    action: browserHistory.action,
    location: browserHistory.location,
  });

  useLayoutEffect(() => browserHistory.listen(setState), [browserHistory]);

  return (
    <reatomContext.Provider value={ctx}>
      <Router
        location={location}
        navigator={browserHistory}
        navigationType={action}
      >
        <ThemeProvider>
          <Toaster
            position="top-right"
            toastOptions={{
              className: 'h-toast',
              duration: 5000,
            }}
          />
          <DataWrapper>
            <AppRouter />
          </DataWrapper>
        </ThemeProvider>
      </Router>
    </reatomContext.Provider>
  );
};

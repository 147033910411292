import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type NavigateOptions = {
  is_active?: boolean;
  target?: '_self' | '_blank';
};

type Navigate = (
  path: string,
  params?: NavigateOptions,
) => (e: React.MouseEvent) => void;

export type GoToPath = (
  path: string,
  metaKey: boolean,
  params?: NavigateOptions,
) => void;

export const useAnchorNavigate = (disabled?: boolean): [Navigate, GoToPath] => {
  const routeNavigate = useNavigate();

  const goToPath = useCallback<GoToPath>(
    (path, metaKey, params) => {
      const { is_active = true, target = '_blank' } = params ?? {};
      if (is_active && !disabled) {
        if (metaKey || (path[0] !== '/' && path[0] !== '?')) {
          window.open(path, target);
        } else {
          routeNavigate(path);
        }
      }
    },
    [disabled],
  );

  const navigate = useCallback<Navigate>(
    (path, params) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      goToPath(path, e.metaKey, params);
      return;
    },
    [disabled],
  );

  return [navigate, goToPath];
};

import { getIAACompleted } from '##/api/actions/iaa';
import { clearUuid } from '##/atoms/uuid';
import { isStatusSuccess } from './requests';

export const checkIaaIsCompleted = (
  uuid: string,
  user_id: number | undefined,
  token?: string,
) => {
  const fetchData = async () => {
    const {
      status,
      data: { data },
    } = await getIAACompleted(uuid, user_id, token);
    if (isStatusSuccess(status)) {
      clearUuid();

      window.onbeforeunload = null;
      window.onunload = null;

      // @ts-ignore
      window.location.replace(data.url);
    }
  };
  fetchData();
};

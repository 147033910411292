import { useFlag } from '@consta/uikit/useFlag';
import { useEffect, useState } from 'react';
import { useAtom } from '@reatom/npm-react';
import { useSearchParams } from 'react-router-dom';
import { mfaApproveDisconnect, mfaRetry } from '##/api/actions/mfa';
import { executeIaaAction } from '##/api/actions/iaa';
import { useUuid } from '##/hooks/useUuid';
import { mfaModeAtom } from '##/atoms/mfa';
import { isStatusSuccess } from '##/utils/requests';
import { accountIdAtom } from '##/atoms/accounts';
import { handleError } from '##/utils/errorsConverter';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { browserHistory } from '##/App';
import { useTimer } from '##/hooks/useTimer';
import { clearUuid } from '##/atoms/uuid';
import { checkIaaIsCompleted } from '##/utils/iaa_completed';
import { useIntervalCallback } from '##/hooks/useIntervalCallback';

const DEFAULT_TIMER_VALUE = 60;

const retryActionMap = {
  inside: 'approve',
  disconnect: 'disconnect_user',
  connect: 'connect_user',
} as const;

const RELOAD_KEY = 'magiclime-limid-reload';

export const useForm = () => {
  const [code, setCode] = useState<string[]>(
    Array.from({ length: 6 }).fill('') as string[],
  );
  const [isBruteForceError, setIsBruteForceError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useFlag();
  const [mfaMode] = useAtom(mfaModeAtom);
  const [uuid] = useUuid();

  const [user_id] = useAtom(accountIdAtom);

  const [_navigate, goToPath] = useAnchorNavigate();

  const { seconds, initTimer } = useTimer(30);

  const isCodeValid = (val: string | undefined) => !!(val && /^\d+$/.test(val));

  const [searchParams] = useSearchParams();
  // @ts-ignore
  const token: string | undefined = searchParams.get('token');

  const onSendCode = async () => {
    if (isBruteForceError) setIsBruteForceError(false);

    try {
      initTimer(DEFAULT_TIMER_VALUE);
      await mfaRetry(retryActionMap[mfaMode], uuid, user_id, token);
    } catch (e) {
      const { time, code } = handleError(e, {
        withToast: (code) => code === 10,
      });
      if (code === 10) {
        initTimer(Number(time));
      } else {
        initTimer(DEFAULT_TIMER_VALUE);
      }
    }
  };

  const onSubmit = async () => {
    try {
      setIsLoading.on();
      if (mfaMode === 'disconnect') {
        const { status } = await mfaApproveDisconnect(
          uuid,
          code.join(''),
          user_id,
          token,
        );
        if (isStatusSuccess(status)) {
          clearUuid();
          goToPath('/mfa/success', false);
        }
      } else {
        const {
          data: { data },
          status,
        } = await executeIaaAction(
          'multifactor',
          uuid ?? '',
          {
            code: code.join(''),
          },
          {
            user_id,
          },
        );
        if (isStatusSuccess(status)) {
          if (mfaMode === 'inside') {
            if (data.type === 'common') {
              window.onbeforeunload = null;
              window.onunload = null;
              clearUuid();
              goToPath(data.url, false, {
                target: '_self',
              });
            }
          } else {
            clearUuid();
            goToPath('/mfa/success', false);
          }
        }
      }
    } catch (e) {
      const { code, time } = handleError(e);
      if (code === 10) {
        initTimer(Number(time));
        setIsBruteForceError(true);
      }
      setCode(['', '', '', '', '', '']);
    } finally {
      setIsLoading.off();
    }
  };

  useIntervalCallback({
    callback: () => checkIaaIsCompleted(uuid, user_id, token),
  });

  useEffect(() => {
    if (localStorage.getItem(RELOAD_KEY) === 'true') {
      localStorage.setItem(RELOAD_KEY, 'false');
      browserHistory.go(-1);
    }
    window.onbeforeunload = () => true;
    window.onunload = () => {
      localStorage.setItem(RELOAD_KEY, 'true');
    };
    return () => {
      window.onbeforeunload = null;
      window.onunload = null;
    };
  }, []);

  useEffect(() => {
    const isValidAllCodes = code.every(isCodeValid);

    if (isValidAllCodes) {
      onSubmit();
    }
  }, [code]);

  return {
    isLoading,
    seconds,
    isBruteForceError,
    onSendCode,
    code,
    setCode,
  };
};

import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormikErrors, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useFlag } from '@consta/uikit/useFlag';
import { useAtom } from '@reatom/npm-react';
import { getSponsor, updateSponsor } from '##/api/actions/users';
import { useTimer } from '##/hooks/useTimer';
import { handleError } from '##/utils/errorsConverter';
import { getValidationMessages } from '##/utils/validation';
import { redirectUrlAtom } from '##/atoms/application';
import { isStatusSuccess } from '##/utils/requests';
import { useAnchorNavigate } from '##/hooks/useAnchorNavigate';
import { accountIdAtom } from '##/atoms/accounts';
import { useUuid } from '##/hooks/useUuid';

type Values = {
  partner_code: string | null;
};

export const useForm = () => {
  const [sponsorName, setSponsorName] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useFlag();
  const initialValues: Values = {
    partner_code: null,
  };

  const [url] = useAtom(redirectUrlAtom);
  const [user_id] = useAtom(accountIdAtom);
  const [uuid] = useUuid();

  const [_navigate, goToPath] = useAnchorNavigate();

  const { seconds, initTimer } = useTimer();

  const { t } = useTranslation();

  const schema = Yup.object().shape({
    partner_code: Yup.lazy((value) => {
      if (value && value.trim().length > 0) {
        return Yup.string().length(40, t('errors.partner_code')?.toString());
      }
      return Yup.string().trim().length(0).nullable();
    }),
  });

  const loadSponsorName = async (code: string) => {
    try {
      const { data, status } = await getSponsor(code);
      setSponsorName(data.data.username);
    } catch (e) {
      const { code, time } = handleError(e, {
        withToast: false,
      });
      if (code === 10) {
        initTimer(Number(time));
      }
      setSponsorName('$error_name');
    }
  };

  const onSubmit = async (
    values: Values,
    skipValidation = true,
    helpers?: FormikHelpers<Values>,
  ) => {
    try {
      setIsLoading.on();
      if (skipValidation || (values.partner_code && !skipValidation)) {
        const { status, data } = await updateSponsor(
          uuid,
          values.partner_code ?? undefined,
          user_id,
        );
        if (isStatusSuccess(status) && data.data.type === 'common') {
          goToPath(data.data.url ?? '', false, { target: '_self' });
        }
      } else {
        helpers?.setErrors({ partner_code: getValidationMessages(t).required });
      }
    } catch (e) {
      const { code, time } = handleError(e);
      if (code === 10) {
        initTimer(Number(time));
      }
    } finally {
      setIsLoading.off();
    }
  };

  return {
    initialValues,
    schema,
    onSubmit,
    loadSponsorName,
    clearSponsor: () => setSponsorName(undefined),
    sponsorName,
    isLoading,
    seconds,
  };
};
